import downloadIcon from "assets/images/download-icon.svg";
import classNames from "classnames";

import AgentInitials from "components/AgentInitials";

import {Attachment} from "lib/api/secureMail";

import {formatInCentralTime} from "lib/formaInCentralTime";
import {getEmployeeFullName} from "lib/formatting";
import styles from "./MessageBubble.module.css";

type Props = {
  timestamp: Date;
  message: string;
  dwbuid: number;
  isEmployee: boolean;
  employeeFirstName: string | null;
  employeeLastName: string | null;
  customerFirstName: string;
  customerLastName: string;
  className?: string;
  attachments?: Attachment[];
  onAttachmentClick?: (attachmentId: string) => void;
  attachmentButtonDisabled?: boolean;
  timeRead: Date | null;
};

function LabeledInfo(props: {label: string; value: string}) {
  return (
    <div className={classNames(styles.row, styles.labeledInfo)}>
      <span className={styles.label}>{props.label}</span>
      <span className={styles.value}>{props.value}</span>
    </div>
  );
}

export default function MessageBubble(props: Props) {
  const formattedTimestamp = formatInCentralTime(
    props.timestamp,
    "MM/dd/yyyy hh:mm a",
  );

  const formattedTimeRead = props.timeRead
    ? formatInCentralTime(props.timeRead, "MM/dd/yyyy hh:mm a")
    : null;

  const employeeFullname = getEmployeeFullName(
    props.isEmployee,
    props.employeeFirstName,
    props.employeeLastName,
  );
  const customerFullname = props.isEmployee
    ? null
    : `${props.customerFirstName} ${props.customerLastName}`;

  return (
    <div
      className={classNames(
        styles.container,
        props.isEmployee ? styles.outgoing : styles.incoming,
        props.className,
      )}
    >
      <div className={styles.bubble}>
        <span className={classNames(styles.row, styles.timestamp)}>
          {formattedTimestamp}
          {formattedTimeRead ? (
            <div>{`Read on ${formattedTimeRead}`}</div>
          ) : null}
        </span>
        {props.attachments ? (
          <div className={styles.attachmentsContainer}>
            {props.attachments.map((attachment) => {
              return (
                <button
                  className={styles.attachmentButton}
                  key={attachment.attachmentId}
                  disabled={props.attachmentButtonDisabled}
                  onClick={() => {
                    props.onAttachmentClick?.(attachment.attachmentId);
                  }}
                >
                  <span className={styles.attachmentName}>
                    {attachment.name}
                  </span>
                  {<img src={downloadIcon} alt="" />}
                </button>
              );
            })}
          </div>
        ) : null}
        {props.message ? (
          <p className={styles.message}>{props.message}</p>
        ) : null}
        <div className={classNames(styles.row, styles.rowGap)}>
          <LabeledInfo label="Account ID:" value={props.dwbuid.toString()} />
          {employeeFullname && (
            <LabeledInfo label="Employee:" value={employeeFullname} />
          )}
          {customerFullname && (
            <LabeledInfo label="CM:" value={customerFullname} />
          )}
        </div>
      </div>
      {props.isEmployee && (
        <AgentInitials
          firstName={props.employeeFirstName}
          lastName={props.employeeLastName}
        />
      )}
    </div>
  );
}
