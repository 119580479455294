import classNames from "classnames";
import FixedPaymentAmountSelectionButton from "components/FixedPaymentAmountSelectionButton";
import PaymentAmountSelectionButton from "components/PaymentAmountSelectionButton";

import styles from "./PaymentAmountSelector.module.css";

const strings = {
  minPaymentDue: "Minimum Payment Due",
  paymentFull: "Payment In Full",
};

export type PaymentAmountOption = "MINIMUM" | "FULL" | "FIXED";

type Props = {
  className?: string;
  selectedPaymentAmountOption: PaymentAmountOption | null;
  onPressPaymentAmountOption: (amount: PaymentAmountOption) => void;
  fixedAmount: string;
  setFixedAmount: (val: string) => void;
  fixedAmountErrorMessage: string | undefined;
  validateFixedAmount: () => void;
};
export default function PaymentAmountSelector({
  className,
  selectedPaymentAmountOption,
  onPressPaymentAmountOption,
  fixedAmount,
  setFixedAmount,
  fixedAmountErrorMessage,
  validateFixedAmount,
}: Props) {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.row}>
        <PaymentAmountSelectionButton
          className={styles.minPaymentDueButton}
          isSelected={selectedPaymentAmountOption === "MINIMUM"}
          onClick={() => onPressPaymentAmountOption("MINIMUM")}
          title={strings.minPaymentDue}
        />
        <PaymentAmountSelectionButton
          className={styles.button}
          isSelected={selectedPaymentAmountOption === "FULL"}
          onClick={() => onPressPaymentAmountOption("FULL")}
          title={strings.paymentFull}
        />
      </div>
      <FixedPaymentAmountSelectionButton
        className={styles.button}
        isSelected={selectedPaymentAmountOption === "FIXED"}
        onClick={() => onPressPaymentAmountOption("FIXED")}
        value={fixedAmount}
        onChangeText={setFixedAmount}
        onBlur={validateFixedAmount}
        invalidMessage={fixedAmountErrorMessage}
      />
    </div>
  );
}
