import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

import {
  AccountDetailsRouteParams,
  AccountRouteParams,
  getAccount,
  getAccountDetails,
  patchAccount,
  patchRegistration,
  postAccountChangeUsername,
  postSendTestPushNotification,
} from "lib/api/account";
import {AgentPortalUseMutationOptions} from "lib/api/react-query/base";

export function useGetAccountQuery({dwbuid}: AccountRouteParams) {
  return useQuery({
    queryKey: ["account", dwbuid],
    queryFn: () => getAccount({dwbuid}),
    enabled: !!dwbuid,
    staleTime: 300000,
  });
}

export function useGetAccountDetailsQuery({dwbuid}: AccountDetailsRouteParams) {
  return useQuery({
    queryKey: ["accountDetails", dwbuid],
    queryFn: () => getAccountDetails({dwbuid}),
    enabled: !!dwbuid,
  });
}

export function useUnlockAccountMutation(
  options?: AgentPortalUseMutationOptions,
) {
  const queryClient = useQueryClient();
  return useMutation(patchAccount, {
    ...options,
    onSuccess: (_, {dwbuid}) => {
      queryClient.invalidateQueries(["accountDetails", dwbuid]);
    },
  });
}

export function useChangeUsernameMutation(
  options?: AgentPortalUseMutationOptions,
) {
  const queryClient = useQueryClient();
  return useMutation(postAccountChangeUsername, {
    ...options,
    onSuccess: (_, {dwbuid}) => {
      queryClient.invalidateQueries(["accountDetails", dwbuid]);
    },
  });
}

export function useSendTestPushNotificationMutation(
  options?: AgentPortalUseMutationOptions,
) {
  return useMutation(postSendTestPushNotification, options);
}

export function useUnlockUnregisteredAccountMutation(
  options?: AgentPortalUseMutationOptions,
) {
  const queryClient = useQueryClient();
  return useMutation(patchRegistration, {
    ...options,
    onSuccess: (_, {dwbuid}) => {
      queryClient.invalidateQueries(["accountDetails", String(dwbuid)]);
    },
  });
}
