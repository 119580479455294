import {useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

import Button from "components/Button";
import TextInput from "components/TextInput";

import {useLoginMutation} from "lib/api/react-query/auth";

import styles from "./LoginPage.module.css";

const strings = {
  loginError: "Sorry, there was an error logging you in.",
};

type LoginProps = {
  username: string;
  setUsername: (newUsername: string) => void;
  password: string;
  setPassword: (newPassword: string) => void;
};

function LoginInfo({username, setUsername, password, setPassword}: LoginProps) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {mutateAsync: login, isLoading} = useLoginMutation();

  const redirectUrl = searchParams.get("redirect");

  return (
    <div className={styles.loginInfo}>
      <span className={styles.agentPortal}>Agent Portal</span>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          login({username, password})
            .then(() => {
              navigate(
                redirectUrl && redirectUrl.startsWith("/") ? redirectUrl : "/",
              );
            })
            .catch(() => {
              alert(strings.loginError);
            });
        }}
      >
        <TextInput
          className={styles.textInput}
          placeholder="Username"
          value={username}
          onChangeText={setUsername}
        />
        <TextInput
          className={styles.textInput}
          type="password"
          placeholder="Password"
          value={password}
          onChangeText={setPassword}
        />
        <Button
          className={styles.signInButton}
          labelClassName={styles.signInText}
          disabled={username === "" || password === ""}
          loading={isLoading}
          variant="filled"
          onClick={() => {
            return;
          }}
        >
          Sign In
        </Button>
      </form>
    </div>
  );
}

export default function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className={styles.container}>
      <LoginInfo
        username={username}
        setUsername={setUsername}
        password={password}
        setPassword={setPassword}
      />
    </div>
  );
}
