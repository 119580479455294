import classNames from "classnames";

import ColoredLabelBox from "components/ColoredLabelBox";
import PaginationControl from "components/PaginationControl";
import Table from "components/Table";

import {
  ClassificationMailListResponse,
  ClassificationMailResponse,
  SecureMailConversationType,
} from "lib/api/secureMail";

import {formatInCentralTime} from "lib/formaInCentralTime";
import styles from "./SecureMailTable.module.css";

function getUpdateDateStatus(updateDate: Date): "success" | "danger" {
  const d = new Date();
  d.setDate(d.getDate() - 1); // 1 day ago
  if (updateDate < d) {
    return "danger";
  }
  return "success";
}

type Props = {
  className?: string;
  variant: "classification" | "cardmember";
  secureMail: ClassificationMailListResponse<Date>;
  selectedPageNumber: number;
  pageSize: number;
  onChangePage: (newPageIndex: number) => void;
  onClickRow?: (id: string) => void;
};

const ACCOUNT_COLUMN_SIZE = 3;
const SUBJECT_COLUMN_SIZE = 10;
const LAST_UPDATED_COLUMN_SIZE = 4;
const STATUS_COLUMN_SIZE = 3;

export default function SecureMailTable({
  className,
  variant,
  secureMail,
  selectedPageNumber,
  pageSize,
  onChangePage,
  onClickRow,
}: Props) {
  const columns: {
    header: string;
    alignment: "left" | "center" | "right";
    size: number;
    render: (row: ClassificationMailResponse<Date>) => React.ReactNode;
  }[] = [];

  if (variant === "classification") {
    columns.push({
      header: "Account ID",
      size: ACCOUNT_COLUMN_SIZE,
      alignment: "left",
      render: (row) => row.dwbuid,
    });
  }

  columns.push(
    {
      header: "Subject",
      size: SUBJECT_COLUMN_SIZE,
      alignment: "left",
      render: (row) => row.subject,
    },
    {
      header: "Last Updated",
      size: LAST_UPDATED_COLUMN_SIZE,
      alignment: "center",
      render: (row) => (
        <ColoredLabelBox status={getUpdateDateStatus(row.updateDate)}>
          {formatInCentralTime(row.updateDate, "MM/dd/yy p")}
        </ColoredLabelBox>
      ),
    },
    {
      header: "Status",
      size: STATUS_COLUMN_SIZE,
      alignment: "center",
      render: (row) => (
        <ColoredLabelBox
          status={
            row.type !== SecureMailConversationType.ReadOnly
              ? "success"
              : "danger"
          }
        >
          {row.type}
        </ColoredLabelBox>
      ),
    },
  );

  const numPages = Math.ceil(secureMail.total / pageSize);

  const firstEntry = selectedPageNumber * pageSize + 1;
  const lastEntry = Math.min(
    (selectedPageNumber + 1) * pageSize,
    secureMail.total,
  );

  return (
    <div className={classNames(styles.container, className)}>
      <Table
        columns={columns}
        data={secureMail.secureMail}
        onClickRow={(row) => onClickRow?.(row.secureMailId)}
      />
      <div className={styles.paginationControlContainer}>
        <h3 className={styles.currentEntries}>
          {`Showing ${firstEntry} to ${lastEntry} of ${secureMail.total} entries`}
        </h3>
        <PaginationControl
          className={styles.paginationControl}
          selectedPageIndex={selectedPageNumber}
          numPages={numPages}
          onPress={onChangePage}
        />
      </div>
    </div>
  );
}
