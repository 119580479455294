import classNames from "classnames";

import styles from "./ActivityIndicator.module.css";

type Props = {
  className?: string;
  color?: string;
};

export default function ActivityIndicator(props: Props) {
  return (
    <div
      className={classNames(
        styles.activityIndicator,
        styles.ldsEllipsis,
        props.className,
      )}
    >
      <div style={{backgroundColor: props.color ?? "#22215f"}} />
      <div style={{backgroundColor: props.color ?? "#22215f"}} />
      <div style={{backgroundColor: props.color ?? "#22215f"}} />
      <div style={{backgroundColor: props.color ?? "#22215f"}} />
    </div>
  );
}
