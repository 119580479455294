import Button from "components/Button";
import {useSendTestPushNotificationMutation} from "lib/api/react-query/account";
import styles from "./AccountOverviewPage.module.css";

const strings = {
  sendTestPushNotification: "Send Test Push Notification",
};

type SendTestPushNotificationButtonProps = {
  partyId: number;
  disabled?: boolean;
};
export default function SendTestPushNotificationButton({
  partyId,
  disabled = false,
}: SendTestPushNotificationButtonProps) {
  const {
    mutateAsync: sendTestPushNotification,
    isLoading: isSendTestPushNotificationLoading,
  } = useSendTestPushNotificationMutation();
  const onClickSendTestPushNotification = () =>
    sendTestPushNotification({partyId});
  return (
    <div className={styles.sendPushSection}>
      <Button
        className={styles.button}
        variant={"filled"}
        onClick={onClickSendTestPushNotification}
        loading={isSendTestPushNotificationLoading}
        disabled={disabled}
      >
        {strings.sendTestPushNotification}
      </Button>
    </div>
  );
}
