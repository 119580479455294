import classNames from "classnames";

import AgentInitials from "components/AgentInitials";
import {Employee} from "lib/api/secureMail";

import styles from "./MessagesHeader.module.css";

type Props = {
  className?: string;
  subject?: string;
  dwbuid?: number;
  employees?: Employee[] | undefined;
  customerFirstName?: string;
  customerLastName?: string;
  isRequestLoading?: boolean;
};

export default function MessagesHeader(props: Props) {
  const customerFirstName = `${props.customerFirstName} ${props.customerLastName}`;
  return (
    <div
      className={classNames(
        styles.row,
        styles.spaceBetween,
        styles.header,
        props.className,
      )}
    >
      <div className={styles.col}>
        <span className={styles.messageSubject}>
          {props.isRequestLoading ? "Loading Subject..." : props.subject}
        </span>
        <span className={styles.accountId}>{`CM Name: ${
          !props.isRequestLoading ? customerFirstName : "Loading..."
        }`}</span>
        <span className={styles.accountId}>
          {`Account ID: ${
            !props.isRequestLoading ? props.dwbuid : "Loading..."
          }`}
        </span>
      </div>
      <div className={classNames(styles.row, styles.columnGap)}>
        {props.employees?.map((employee) => {
          return (
            <AgentInitials
              key={employee.employeeId}
              firstName={employee.firstname}
              lastName={employee.lastname}
            />
          );
        })}
      </div>
    </div>
  );
}
