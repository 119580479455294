import Button from "components/Button";
import StyledModal from "components/StyledModal";

import styles from "./SessionExpiredModal.module.css";

const strings = {
  title: "Session Expired",
  message: "Please sign in again.",
  ok: "OK",
};

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
};
export default function SessionExpiredModal({isOpen, onRequestClose}: Props) {
  return (
    <StyledModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className={styles.container}>
        <span className={styles.title}>{strings.title}</span>
        <span className={styles.message}>{strings.message}</span>
        <div className={styles.buttonContainer}>
          <Button variant="filled" onClick={onRequestClose}>
            {strings.ok}
          </Button>
        </div>
      </div>
    </StyledModal>
  );
}
