import leftPaginationArrow from "assets/images/left-pagination-arrow.svg";
import rightPaginationArrow from "assets/images/right-pagination-arrow.svg";
import classNames from "classnames";

import styles from "./PaginationControl.module.css";

type ArrowButtonProps = {
  isBackButton: boolean;
  selectedPageIndex: number;
  numPages: number;
  onPress: (newPageIndex: number) => void;
};

function ArrowButton({
  isBackButton,
  selectedPageIndex,
  numPages,
  onPress,
}: ArrowButtonProps) {
  return (
    <button
      disabled={
        (isBackButton && selectedPageIndex <= 0) ||
        (!isBackButton && selectedPageIndex >= numPages)
      }
      className={classNames(styles.paginationButton, styles.arrowButton)}
      onClick={() => {
        onPress(selectedPageIndex + (isBackButton ? -1 : 1));
      }}
    >
      <img
        className={styles.buttonImageContent}
        src={isBackButton ? leftPaginationArrow : rightPaginationArrow}
        alt="arrow"
      />
    </button>
  );
}

type PageButtonProps = {
  isSelected: boolean;
  pageIndex: number;
  onPress: (newPageIndex: number) => void;
};

function PageButton({isSelected, pageIndex, onPress}: PageButtonProps) {
  return (
    <button
      disabled={pageIndex < 0}
      className={classNames(
        styles.paginationButton,
        isSelected && styles.selectedButton,
      )}
      onClick={() => {
        onPress(pageIndex);
      }}
    >
      <span
        className={classNames(
          styles.buttonTextContent,
          isSelected ? styles.selectedText : styles.unselectedText,
        )}
      >
        {pageIndex < 0 ? "..." : pageIndex + 1}
      </span>
    </button>
  );
}

type Props = {
  className?: string;
  selectedPageIndex: number;
  numPages: number;
  onPress: (newPageIndex: number) => void;
};

export default function PaginationControl({
  className,
  selectedPageIndex,
  numPages,
  onPress,
}: Props) {
  // determine page bounds
  const startIndex = Math.max(1, selectedPageIndex - 2);
  const endIndex = Math.min(numPages - 2, selectedPageIndex + 2);

  let clickablePages: number[] = [];
  const clickableLength = endIndex - startIndex + 1;
  if (clickableLength > 0) {
    clickablePages = Array(endIndex - startIndex + 1)
      .fill(1)
      .map((_, idx) => startIndex + idx);
  }

  return (
    <div className={classNames(styles.container, className)}>
      <ArrowButton
        isBackButton={true}
        selectedPageIndex={selectedPageIndex}
        numPages={numPages}
        onPress={onPress}
      />
      <PageButton
        isSelected={selectedPageIndex === 0}
        pageIndex={0}
        onPress={onPress}
      />
      {selectedPageIndex - 2 > 1 ? (
        <PageButton isSelected={false} pageIndex={-1} onPress={onPress} />
      ) : null}

      {clickablePages.map((pageIndex, i) => (
        <PageButton
          key={i}
          isSelected={selectedPageIndex === pageIndex}
          pageIndex={pageIndex}
          onPress={onPress}
        />
      ))}

      {selectedPageIndex + 3 < numPages - 1 ? (
        <PageButton isSelected={false} pageIndex={-1} onPress={onPress} />
      ) : null}
      {numPages > 1 ? (
        <PageButton
          isSelected={selectedPageIndex === numPages - 1}
          pageIndex={numPages - 1}
          onPress={onPress}
        />
      ) : null}
      <ArrowButton
        isBackButton={false}
        selectedPageIndex={selectedPageIndex}
        numPages={numPages - 1}
        onPress={onPress}
      />
    </div>
  );
}
