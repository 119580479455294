import classNames from "classnames";

import ActivityIndicator from "components/ActivityIndicator";
import ClassificationCard from "components/ClassificationCard";

import {useGetClassificationCount} from "lib/api/react-query/classification";

import styles from "./ClassificationList.module.css";

type Props = {
  className?: string;
  selected: number;
  onChange: (index: number) => void;
};

export default function ClassificationList({
  className,
  selected,
  onChange,
}: Props) {
  const {data: classificationCount, isLoading: isClassificationCountLoading} =
    useGetClassificationCount();

  return (
    <div className={classNames(styles.classificationList, className)}>
      {!isClassificationCountLoading ? (
        Object.values(classificationCount?.classifications ?? {}).map(
          (classification) => {
            return (
              <ClassificationCard
                key={classification.id}
                id={classification.id}
                title={classification.description}
                numberOfItems={classification.count}
                onClick={onChange}
                selected={selected === classification.id}
              />
            );
          },
        )
      ) : (
        <ActivityIndicator className={styles.loader} />
      )}
    </div>
  );
}
