import {useMutation, useQuery} from "@tanstack/react-query";

import {getStaffAccount, LoginRequest} from "lib/api/auth";
import {AgentPortalUseMutationOptions} from "lib/api/react-query/base";
import {signIn, signOut} from "lib/auth";

export function useLoginMutation(options?: AgentPortalUseMutationOptions) {
  return useMutation(
    (req: LoginRequest) => signIn(req.username, req.password),
    options,
  );
}

export function useLogoutMutation(options?: AgentPortalUseMutationOptions) {
  return useMutation(signOut, options);
}

export function useGetStaffAccountQuery() {
  return useQuery({
    queryKey: ["staff-account"],
    queryFn: () => getStaffAccount(),
  });
}
