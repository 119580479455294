import Button from "components/Button";
import StyledModal from "components/StyledModal";

import styles from "./ConfirmationModal.module.css";

type Props = {
  isOpen: boolean;
  title?: string;
  onConfirm: () => void;
  onCancel: () => void;
  labelConfirm?: string;
  labelCancel?: string;
  isLoadingConfirmation?: boolean;
  canConfirm: boolean;
  children: React.ReactNode;
};

export default function ConfirmationModal({
  isOpen,
  title,
  onConfirm,
  onCancel,
  labelConfirm = "Yes",
  labelCancel = "No",
  isLoadingConfirmation,
  canConfirm,
  children,
}: Props) {
  return (
    <StyledModal isOpen={isOpen} onRequestClose={onCancel}>
      <div className={styles.container}>
        {title ? <span className={styles.title}>{title}</span> : null}
        {children}
        <div className={styles.buttonContainer}>
          <Button
            variant="filled"
            onClick={onConfirm}
            loading={isLoadingConfirmation}
            disabled={!canConfirm}
          >
            {labelConfirm}
          </Button>
          <Button variant="unfilled" onClick={onCancel}>
            {labelCancel}
          </Button>
        </div>
      </div>
    </StyledModal>
  );
}
