import addIcon from "assets/images/add-image-icon.svg";
import removeIcon from "assets/images/remove-image-icon.svg";
import sendIcon from "assets/images/send-icon.svg";
import uploadIcon from "assets/images/upload-icon.svg";
import classNames from "classnames";
import ActivityIndicator from "components/ActivityIndicator";
import {useRef, useState} from "react";

import styles from "./MessageInput.module.css";

export const secureMailBodyMaxLength = 4000;

function getFileType(fileType: string) {
  const typeParts = fileType.split("/");
  if (typeParts.length > 1) {
    return typeParts[1];
  }
  return fileType;
}

type Props = {
  className?: string;
  message: string;
  attachments: File[] | undefined;
  onChangeMessage: (value: string) => void;
  onSend: () => void;
  onAttachment: (value: File[] | undefined) => void;
  isSendMessageLoading: boolean;
  maxAttachments?: number;
};

export default function MessageInput({
  className,
  message,
  attachments,
  onChangeMessage,
  onSend,
  onAttachment,
  isSendMessageLoading,
  maxAttachments,
}: Props) {
  const fileInputField = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleClick = () => {
    if (fileInputField && fileInputField.current) {
      fileInputField.current.click();
    }
  };

  const handleFileChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      onAttachment(
        attachments ? [...attachments, selectedFile] : [selectedFile],
      );
    }
  };

  return (
    <div className={classNames(className, styles.container)}>
      <div
        className={
          attachments !== undefined
            ? isFocused
              ? classNames(styles.fileInput, styles.focusedFileInput)
              : styles.fileInput
            : styles.hidden
        }
      >
        {attachments !== undefined
          ? attachments.map((image, imgIndex) => (
              <div key={imgIndex} className={styles.imageInfoContainer}>
                {image.type.split("/")[0] === "image" ? (
                  <img
                    src={URL.createObjectURL(image)}
                    alt=""
                    className={styles.attachmentPreview}
                  />
                ) : (
                  <span
                    className={classNames(
                      styles.attachmentPreview,
                      styles.attachmentPreviewBackground,
                    )}
                  >
                    <span className={styles.fileType}>
                      {getFileType(image.type).toUpperCase()}
                    </span>
                  </span>
                )}
                <div className={styles.imageNameContainer}>
                  <div className={styles.imageNameText}>{image.name}</div>
                </div>
                <button
                  onClick={() => {
                    if (attachments !== undefined) {
                      if (attachments.length === 1) {
                        onAttachment(undefined);
                      } else {
                        onAttachment(
                          attachments.filter((_, index) => {
                            return index !== imgIndex;
                          }),
                        );
                      }
                    }
                  }}
                  className={styles.removeIcon}
                >
                  <img src={removeIcon} alt="" />
                </button>
              </div>
            ))
          : null}
        {attachments !== undefined &&
        (!maxAttachments || attachments.length < maxAttachments) ? (
          <button onClick={handleClick} className={styles.addImageBox}>
            <img src={addIcon} alt="" />
          </button>
        ) : null}
      </div>
      <div className={styles.messageInputContainer}>
        <textarea
          className={
            attachments !== undefined
              ? classNames(
                  styles.messageInput,
                  styles.messageInputWithAttachments,
                )
              : styles.messageInput
          }
          placeholder="Enter Message Here"
          value={message}
          onChange={(e) => {
            onChangeMessage(e.target.value);
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          maxLength={secureMailBodyMaxLength}
          disabled={isSendMessageLoading}
          onInput={(e) => {
            const messageReplyBox = e.currentTarget;
            if (messageReplyBox) {
              messageReplyBox.setAttribute(
                "style",
                "height:" + messageReplyBox.scrollHeight + "px;",
              );
            }
          }}
        />
        {!isSendMessageLoading ? (
          <>
            <button
              className={classNames(styles.sendButton, styles.button)}
              onClick={() => onSend()}
            >
              <img src={sendIcon} alt="send message" />
            </button>
            <input
              type="file"
              accept=".pdf"
              ref={fileInputField}
              className={styles.hidden}
              onChange={(e) => {
                handleFileChange(e);
              }}
            />
            <button
              className={classNames(styles.uploadButton, styles.button)}
              onClick={handleClick}
              disabled={
                !!maxAttachments && attachments?.length === maxAttachments
              }
            >
              <img src={uploadIcon} alt="upload file" />
            </button>
          </>
        ) : (
          <ActivityIndicator className={styles.spinner} />
        )}
      </div>
    </div>
  );
}
