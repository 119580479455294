import ActivityIndicator from "components/ActivityIndicator";
import MessageInput from "components/MessageInput";
import MessagesHeader from "components/MessagesHeader";
import Select from "components/Select";
import TextInput from "components/TextInput";
import {useGetAccountQuery} from "lib/api/react-query/account";
import {useGetClassifications} from "lib/api/react-query/classification";
import {useComposeSecureMailMutation} from "lib/api/react-query/secureMail";
import {SecureMailConversationType} from "lib/api/secureMail";

import MainLayout from "pages/MainLayout";
import {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import styles from "./ComposeSecureMailPage.module.css";

const strings = {
  composeSecureMail: "Compose SecureMail",
  new: "New",
  open: "Open",
  readOnly: "Read Only",
  subjectPlaceholder: "Subject",
};

const getStatusLabel = (type: SecureMailConversationType) => {
  switch (type) {
    case SecureMailConversationType.Open:
      return strings.open;
    case SecureMailConversationType.ReadOnly:
      return strings.readOnly;
    case SecureMailConversationType.New:
      return strings.new;
    default:
      return "";
  }
};

type AssignedClassificationProps = {
  selectedClassification: number;
  setSelectedClassification: (newClassification: number) => void;
  selectedStatus: SecureMailConversationType;
  setSelectedStatus: (status: SecureMailConversationType) => void;
  isComposeSecureMailLoading: boolean;
};

function AssignedClassification(props: AssignedClassificationProps) {
  const {data: classificationsList, isLoading: isClassificationsListLoading} =
    useGetClassifications();

  const isLoading =
    isClassificationsListLoading || props.isComposeSecureMailLoading;

  if (isLoading) {
    return (
      <div className={styles.assignedClassification}>
        <ActivityIndicator className={styles.centeredActivityIndicator} />
      </div>
    );
  }

  return (
    <div className={styles.assignedClassification}>
      <Select
        className={styles.select}
        options={Object.values(classificationsList?.classifications ?? {}).map(
          (classification) => ({
            label: classification.description,
            value: String(classification.id),
          }),
        )}
        selectedValue={String(props.selectedClassification)}
        onChange={(selectedId) =>
          props.setSelectedClassification(parseInt(selectedId))
        }
        disabled={isLoading}
      />

      <Select
        className={styles.select}
        options={Object.values(SecureMailConversationType).map((type) => ({
          label: getStatusLabel(type),
          value: type,
        }))}
        selectedValue={props.selectedStatus}
        onChange={(selectedStatus: SecureMailConversationType) =>
          props.setSelectedStatus(selectedStatus)
        }
        disabled={isLoading}
      />
    </div>
  );
}

export default function ComposeSecureMailPage() {
  const navigate = useNavigate();
  const {dwbuid = ""} = useParams();

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [messageAttachments, setMessageAttachments] = useState<
    File[] | undefined
  >();

  const [selectedClassification, setSelectedClassification] = useState(0);
  const [selectedStatus, setSelctedStatus] = useState(
    SecureMailConversationType.Open,
  );

  const {data: account, isFetching: isGetAccountFetching} = useGetAccountQuery({
    dwbuid,
  });
  const {
    mutateAsync: composeSecureMail,
    isLoading: isComposeSecureMailLoading,
  } = useComposeSecureMailMutation();

  const navigateToAccountOverviewPage = () => {
    navigate(`/account/${dwbuid}`);
  };

  const customerFirstName = isGetAccountFetching
    ? "Loading..."
    : account
    ? `${account.firstName}`
    : "N/A";
  const customerLastName = isGetAccountFetching
    ? ""
    : account
    ? `${account.lastName}`
    : "";
  const partyId = isGetAccountFetching ? "" : account?.partyId;

  const canSendMessage =
    partyId &&
    subject.trim() !== "" &&
    (message.trim() !== "" || messageAttachments !== undefined);

  const onClickSend = () => {
    if (!canSendMessage) {
      return;
    }
    composeSecureMail({
      partyId,
      subject,
      classificationId: selectedClassification,
      status: selectedStatus,
      body: message,
      file:
        messageAttachments && messageAttachments.length > 0
          ? messageAttachments[0]
          : undefined,
    })
      .then((response) => {
        window.history.replaceState({}, "", `/account/${dwbuid}/secure-mail`);
        navigate({
          pathname: `/secure-mail/${response.id}`,
          search: `account=${dwbuid}`,
        });
      })
      .catch(() => {
        alert("There was a problem sending the message.");
      });
  };

  return (
    <MainLayout
      onClickBack={navigateToAccountOverviewPage}
      renderLeftContent={() => (
        <AssignedClassification
          selectedClassification={selectedClassification}
          setSelectedClassification={setSelectedClassification}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelctedStatus}
          isComposeSecureMailLoading={isComposeSecureMailLoading}
        />
      )}
    >
      <div className={styles.container}>
        <div className={styles.title}>{strings.composeSecureMail}</div>
        <MessagesHeader
          className={styles.messagesHeader}
          dwbuid={parseInt(dwbuid)}
          customerFirstName={customerFirstName}
          customerLastName={customerLastName}
          isRequestLoading={isGetAccountFetching}
        />
        <TextInput
          className={styles.subject}
          placeholder={strings.subjectPlaceholder}
          value={subject}
          onChangeText={setSubject}
          invalid={!subject}
        />
        <MessageInput
          className={styles.message}
          message={message}
          onChangeMessage={setMessage}
          attachments={messageAttachments}
          onAttachment={setMessageAttachments}
          maxAttachments={1}
          onSend={onClickSend}
          isSendMessageLoading={isComposeSecureMailLoading}
        />
      </div>
    </MainLayout>
  );
}
