import Button from "components/Button";
import SearchInput from "components/SearchInput";
import {DiagnosticsResponse} from "lib/api/diagnostics";
import {FetchError} from "lib/api/instance";
import {useGetDiagnostics} from "lib/api/react-query/diagnostics";
import MainLayout from "pages/MainLayout";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

import styles from "./DiagnosticsPage.module.css";

const dwbuidSearchParamName = "dwbuid";
const strings = {
  accountSearch: "Account Search",
  searchAccountPrompt: "Search Account ID",
  search: "Search",
  initialDisplayText: "Please enter an account number.",
  accountNotFound: "No account matches the provided search. Please try again.",
  fetchAccountError:
    "Sorry, there was an error requesting the account information.",
};

type LeftSideBarProps = {
  query: string;
  onChangeQuery: (accountNum: string) => void;
  onSearch: () => void;
  loading?: boolean;
};
function LeftSideBar({
  query,
  onChangeQuery,
  onSearch,
  loading,
}: LeftSideBarProps) {
  return (
    <form className={styles.leftSideBar}>
      <span className={styles.selectTitle}>{strings.accountSearch}</span>
      <SearchInput
        className={styles.searchInput}
        value={query}
        onChange={onChangeQuery}
        prompt={strings.searchAccountPrompt}
      />
      <Button
        variant={"unfilled"}
        onClick={(e) => {
          e.preventDefault();
          onSearch();
        }}
        disabled={!query}
        loading={loading}
      >
        {strings.search}
      </Button>
    </form>
  );
}

export default function DiagnosticsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dwbuid, setDWBUID] = useState<string>(
    searchParams.get(dwbuidSearchParamName) ?? "",
  );
  const [diagnostics, setDiagnostics] = useState<DiagnosticsResponse | null>(
    null,
  );
  const [displayText, setDisplayText] = useState(strings.initialDisplayText);

  const {mutateAsync: fetchDiagnostics, isLoading: isFetchDiagnosticsLoading} =
    useGetDiagnostics();

  const onSearch = () => {
    setSearchParams({dwbuid});
  };

  useEffect(() => {
    const dwbuidSearchParam = searchParams.get(dwbuidSearchParamName);
    if (dwbuidSearchParam) {
      fetchDiagnostics({dwbuid: dwbuidSearchParam})
        .then((res) => {
          setDiagnostics(res);
        })
        .catch((err: FetchError) => {
          if (err.response?.status === 401) {
            return;
          }
          if (err.response?.status === 404 || err.response?.status === 400) {
            setDisplayText(strings.accountNotFound);
            setDiagnostics(null);
          } else {
            alert(strings.fetchAccountError);
          }
        });
    }
  }, [fetchDiagnostics, searchParams]);

  return (
    <MainLayout
      renderLeftContent={() => (
        <LeftSideBar
          query={dwbuid}
          onChangeQuery={setDWBUID}
          onSearch={onSearch}
          loading={isFetchDiagnosticsLoading}
        />
      )}
    >
      <div className={styles.rightSideContainer}>
        {diagnostics ? (
          <div>
            {Object.entries(diagnostics).map(([key, value]) => (
              <div key={`${key}:${value}}`} className={styles.diagnostic}>
                <div className={styles.diagnosticKey}>{key}</div>
                <div className={styles.diagnosticValue}>{value}</div>
              </div>
            ))}
          </div>
        ) : (
          <span className={styles.displayText}>{displayText}</span>
        )}
      </div>
    </MainLayout>
  );
}
