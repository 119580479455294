import {useState} from "react";
import {useNavigate} from "react-router-dom";

import Button from "components/Button";
import SearchInput from "components/SearchInput";

import MainLayout from "pages/MainLayout";

import styles from "./AccountPage.module.css";

const strings = {
  searchAccountPrompt: "Search Account ID",
  initialDisplayText: "Please enter an account number.",
};

type LeftSideBarProps = {
  query: string;
  onChangeQuery: (accountNum: string) => void;
  onSearch: () => void;
  loading?: boolean;
};

function LeftSideBar({
  query,
  onChangeQuery,
  onSearch,
  loading,
}: LeftSideBarProps) {
  return (
    <form className={styles.leftSideBar}>
      <span className={styles.selectTitle}>Account Search</span>
      <SearchInput
        className={styles.searchInput}
        value={query}
        onChange={onChangeQuery}
        prompt={strings.searchAccountPrompt}
      />
      <Button
        variant={"unfilled"}
        onClick={(e) => {
          e.preventDefault();
          onSearch();
        }}
        disabled={!query}
        loading={loading}
      >
        Search
      </Button>
    </form>
  );
}

export default function AccountPage() {
  const navigate = useNavigate();
  const [dwbuid, setDWBUID] = useState("");

  const updateAccountNumQuery = (q: string) => {
    const numericPattern = /\D/g;
    setDWBUID(q.replace(numericPattern, ""));
  };

  return (
    <MainLayout
      renderLeftContent={() => (
        <LeftSideBar
          query={dwbuid}
          onChangeQuery={updateAccountNumQuery}
          onSearch={() => {
            navigate({
              pathname: `/account/${dwbuid}`,
            });
          }}
        />
      )}
    >
      <div className={styles.rightSideContainer}>
        <span className={styles.displayText}>{strings.initialDisplayText}</span>
      </div>
    </MainLayout>
  );
}
