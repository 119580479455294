import classNames from "classnames";

import styles from "./EnrollmentIndicator.module.css";

const strings = {
  enrolled: "Enrolled",
  notEnrolled: "Not Enrolled",
};

type Props = {
  className?: string;
  isEnrolled: boolean;
};

export default function EnrollmentIndicator(props: Props) {
  return (
    <div
      className={classNames(props.className, styles.container, {
        [styles.notEnrolled]: !props.isEnrolled,
      })}
    >
      {props.isEnrolled ? strings.enrolled : strings.notEnrolled}
    </div>
  );
}
