import classNames from "classnames";

import styles from "./ClassificationCard.module.css";

type Props = {
  className?: string;
  id: number;
  title: string;
  numberOfItems: number;
  onClick: (id: number) => void;
  selected: boolean;
};

export default function ClassificationCard(props: Props) {
  return (
    <button
      className={classNames(
        styles.classificationCard,
        props.selected ? styles.selected : undefined,
        props.className,
      )}
      onClick={() => props.onClick(props.id)}
    >
      <span className={styles.title}>{props.title}</span>
      <span className={styles.number}>{`(${props.numberOfItems})`}</span>
    </button>
  );
}
