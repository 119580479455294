import searchIcon from "assets/images/search-icon.svg";
import classNames from "classnames";

import styles from "./SearchInput.module.css";

type Props = {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  prompt: string;
};

export default function SearchInput(props: Props) {
  return (
    <div className={classNames(props.className, styles.container)}>
      <img src={searchIcon} alt="" className={styles.icon} />
      <input
        type="search"
        placeholder={props.prompt}
        value={props.value}
        onChange={(event) => {
          props.onChange(event.target.value);
        }}
        className={styles.search}
      />
    </div>
  );
}
