import instance from "lib/api/instance";

export type LoginRequest = {
  username: string;
  password: string;
};

export type LoginResponse = Record<string, never>;

export function login(req: LoginRequest): Promise<LoginResponse> {
  return (
    instance
      .fetch({
        path: "/public/staff-login",
        method: "POST",
        body: JSON.stringify(req),
      })
      //TODO: Potentially re-add ".then((res) => res.json());" after LoginResponse determined
      .then(() => {
        return {};
      })
  );
}

export function logout() {
  return instance.fetch({path: "/staff/logout-staff", method: "POST"});
}

export type StaffAccountRole =
  | "cs-ach"
  | "cs-staff"
  | "cs-usa"
  | "cs-username"
  | "cs-unlock";

export type StaffAccountResponse = {
  roles: StaffAccountRole[];
};

export function getStaffAccount(): Promise<StaffAccountResponse> {
  return instance
    .fetch({
      method: "GET",
      path: `/staff/my-account`,
    })
    .then((res) => res.json());
}
