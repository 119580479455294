import {useEffect} from "react";
import {useLocation} from "react-router-dom";

type Props = {
  title: string;
  children: JSX.Element;
};

export default function PageTitle(props: Props) {
  const pathname = useLocation().pathname;

  useEffect(() => {
    if (pathname.startsWith("/account") && pathname !== "/account")
      document.title = `Account Search - [${pathname.split("/")[2]}]`;
    else document.title = props.title;
  }, [pathname, props.title]);
  return props.children;
}
