import {useQuery} from "@tanstack/react-query";

import {
  getClassificationCount,
  getClassifications,
} from "lib/api/classification";

export function useGetClassifications() {
  return useQuery(["getClassifications"], getClassifications);
}

export function useGetClassificationCount() {
  return useQuery(["getClassificationCount"], getClassificationCount);
}
