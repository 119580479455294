import {FetchError} from "lib/api/instance";

export default function parseFetchError(error: FetchError): string {
  if (!error.responseJson) {
    return "";
  }
  const errorResponse = error.responseJson.errors as Record<string, string[]>;
  if (!errorResponse) {
    return "";
  }

  const errorArr: string[] = [];
  // push 'general' errors first
  if (errorResponse.general) {
    errorArr.push(...errorResponse.general);
  }

  // push other errors
  Object.keys(errorResponse).map((k) => {
    if (k === "general") {
      return;
    }
    errorArr.push(...errorResponse[k]);
  });

  return errorArr.join("\n");
}
