import classNames from "classnames";

import styles from "./ColoredLabelBox.module.css";

type Props = {
  className?: string;
  children: string;
  status: "danger" | "success";
};

export default function ColoredLabelBox({className, children, status}: Props) {
  return (
    <div
      className={classNames(
        styles.container,
        status === "danger" ? styles.dangerStatus : styles.successStatus,
        className,
      )}
    >
      {children}
    </div>
  );
}
