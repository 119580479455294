import {useEffect, useState} from "react";

import ActivityIndicator from "components/ActivityIndicator";
import AgentInitials from "components/AgentInitials";
import AlertModal from "components/AlertModal";
import Button from "components/Button";
import ClassificationCard from "components/ClassificationCard";
import ColoredLabelBox from "components/ColoredLabelBox";
import ConfirmationModal from "components/ConfirmationModal";
import DateInput, {DateString} from "components/DateInput";
import MessageBubble from "components/MessageBubble";
import MessageInput from "components/MessageInput/MessageInput";
import MessagesHeader from "components/MessagesHeader";
import SearchInput from "components/SearchInput";
import SecureMailTable from "components/SecureMailTable";
import Select from "components/Select";
import StyledModal from "components/StyledModal";
import TextInput from "components/TextInput";

import {
  getClassificationCount,
  getClassifications,
} from "lib/api/classification";
import {health} from "lib/api/health";
import {useHealth} from "lib/api/react-query/health";
import {
  getSecureMailThread,
  SecureMailConversationType,
  updateSecureMailStatus,
} from "lib/api/secureMail";

import MainLayout from "pages/MainLayout";

import styles from "./DemoPage.module.css";

export default function DemoPage() {
  useEffect(() => {
    health().then((data) => console.log(data));
    getClassifications().then((data) => console.log(data));
    getClassificationCount().then((data) => console.log(data));
    getSecureMailThread("1").then((data) => console.log(data));
    updateSecureMailStatus({
      id: "1",
      type: SecureMailConversationType.Open,
    }).then((data) => console.log(data));
  }, []);
  const [dateInputDate, setDateInputDate] = useState<DateString>("");
  const [textValue, setTextValue] = useState("");
  const [styledModalVisible, setStyledModalVisible] = useState(false);
  const [alertModalVisible, setAlertModalVisible] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [selectValue, setSelectValue] = useState("");
  const [currentIndex, setCurrentIndex] = useState(1);
  const [messageValue, setMessageValue] = useState("");
  const [messageImages, setMessageImages] = useState<File[] | undefined>(
    undefined,
  );
  const [selectedCard, setSelectedCard] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const {data: healthData, isLoading: isHealthLoading} = useHealth();

  return (
    <MainLayout>
      <h3 className={styles.section}>ActivityIndicator</h3>
      <ActivityIndicator />
      <h3 className={styles.section}>AgentInitials</h3>
      <div className={styles.agentInitialsContainer}>
        <AgentInitials firstName={"O"} lastName={"P"} />
      </div>
      <h3 className={styles.section}>Buttons</h3>
      <div className={styles.buttonContainer}>
        <Button
          variant="filled"
          onClick={() => {
            return;
          }}
        >
          {"Click Me!"}
        </Button>
        <Button
          variant="unfilled"
          onClick={() => {
            return;
          }}
        >
          {"Click Me!"}
        </Button>
        <Button
          variant="filled"
          onClick={() => {
            return;
          }}
          disabled
        >
          {"Click Me!"}
        </Button>
        <Button
          variant="unfilled"
          onClick={() => {
            return;
          }}
          disabled
        >
          {"Click Me!"}
        </Button>
        <Button
          variant="unfilled"
          onClick={() => {
            return;
          }}
          loading
        >
          {"Loading"}
        </Button>
      </div>
      <h3 className={styles.section}>ClassificationCard</h3>
      <div className={styles.classificationCardContainer}>
        <ClassificationCard
          id={0}
          title="Classification Card"
          numberOfItems={10}
          onClick={setSelectedCard}
          selected={selectedCard === 0}
        />
        <ClassificationCard
          id={1}
          title="This is a long classification name just to see what will happen. Another sentence."
          numberOfItems={10}
          onClick={setSelectedCard}
          selected={selectedCard === 1}
        />
        <ClassificationCard
          id={2}
          title="Goodbye"
          numberOfItems={1}
          onClick={setSelectedCard}
          selected={selectedCard === 2}
        />
      </div>
      <h3 className={styles.section}>ColoredLabelBox</h3>
      <ColoredLabelBox className={styles.component} status="success">
        Open
      </ColoredLabelBox>
      <ColoredLabelBox className={styles.component} status="danger">
        07/10/22 9:41PM
      </ColoredLabelBox>
      <h3 className={styles.section}>DateInput</h3>
      <div className={styles.dateInputContainer}>
        <DateInput
          label="Start Date"
          value={dateInputDate}
          onChangeDate={setDateInputDate}
        />
      </div>
      <h3>Health Hook Test</h3>
      {isHealthLoading ? <div>Loading</div> : <div>{healthData?.status}</div>}
      <h3 className={styles.section}>MessageBubble</h3>
      <div className={styles.messageBubbleContainer}>
        <MessageBubble
          timestamp={new Date()}
          message={"Hi, I would like to request a credit line increase."}
          dwbuid={1234567890}
          isEmployee={false}
          employeeFirstName={null}
          employeeLastName={null}
          customerFirstName={"Jane"}
          customerLastName={"Doe"}
          timeRead={null}
        />
        <MessageBubble
          timestamp={new Date()}
          message={
            "I’ve made timely payments for the last 6 months and hope my responsible card usage supports my case for an increase."
          }
          dwbuid={1234567890}
          isEmployee={false}
          employeeFirstName={null}
          employeeLastName={null}
          customerFirstName={"Jane"}
          customerLastName={"Doe"}
          timeRead={null}
        />
        <MessageBubble
          timestamp={new Date()}
          message={
            "Thank you for your credit line increase inquiry. We are reviewing your account and will have a response for you soon."
          }
          dwbuid={1234567890}
          isEmployee={true}
          employeeFirstName={"Owen"}
          employeeLastName={"Powers"}
          customerFirstName={"Jane"}
          customerLastName={"Doe"}
          timeRead={null}
        />
      </div>
      <h3 className={styles.section}>MessageInput</h3>
      <MessageInput
        className={styles.messageInput}
        message={messageValue}
        attachments={messageImages}
        onChangeMessage={setMessageValue}
        onSend={() => {
          setMessageValue("");
          setMessageImages(undefined);
        }}
        onAttachment={setMessageImages}
        isSendMessageLoading={false}
      />
      <h3 className={styles.section}>MessagesHeader</h3>
      <MessagesHeader
        subject={"Message Subject"}
        customerFirstName={"Jane"}
        customerLastName={"Doe"}
        dwbuid={123456}
        employees={[]}
      />
      <h3 className={styles.section}>Search Input</h3>
      <SearchInput
        value={searchValue}
        onChange={setSearchValue}
        prompt="Search"
      />
      <h3 className={styles.section}>Styled Modal</h3>
      <div className={styles.componentContainer}>
        <Button variant="filled" onClick={() => setStyledModalVisible(true)}>
          {"Show StyledModal"}
        </Button>
        <Button variant="filled" onClick={() => setAlertModalVisible(true)}>
          {"Show AlertModal"}
        </Button>
        <Button variant="filled" onClick={() => setConfirmModalVisible(true)}>
          {"Show ConfirmModal"}
        </Button>
      </div>
      <StyledModal
        isOpen={styledModalVisible}
        onRequestClose={() => setStyledModalVisible(false)}
      >
        {"StyledModal"}
      </StyledModal>
      <AlertModal
        isOpen={alertModalVisible}
        title={"Whoops!"}
        message={
          "Passwords do not match, please re-enter passwords and try again."
        }
        buttonText={"Okay"}
        onClickConfirm={() => setAlertModalVisible(false)}
      />
      <ConfirmationModal
        isOpen={confirmModalVisible}
        onCancel={() => setConfirmModalVisible(false)}
        onConfirm={() => setConfirmModalVisible(false)}
        labelConfirm={"Yes, Close"}
        labelCancel={"No, Cancel"}
        canConfirm={true}
      >
        <div>{"Are you sure you want to close this Secure Mail?"}</div>
      </ConfirmationModal>
      <div className={styles.selectContainer}>
        <h3 className={styles.section}>Select</h3>
        <Select
          className={styles.select}
          options={[
            {label: "Option 1", value: "option1"},
            {label: "Option 2", value: "option2"},
            {label: "Option 3", value: "option3"},
          ]}
          selectedValue={selectValue}
          onChange={setSelectValue}
          placeholder="Select an option"
        />
      </div>
      <h3 className={styles.section}>SecureMailTable - Normal</h3>
      <SecureMailTable
        className={styles.component}
        variant={"classification"}
        secureMail={{
          total: 50,
          secureMail: [
            {
              dwbuid: 1,
              subject: `${currentIndex} A Forgot password`,
              updateDate: new Date(),
              type: SecureMailConversationType.Open,
              secureMailId: "be921666-98c2-422e-af05-b53c116312c",
            },
            {
              dwbuid: 2,
              subject: `${currentIndex} B Login issues`,
              updateDate: new Date(),
              type: SecureMailConversationType.ReadOnly,
              secureMailId: "f164ca82-cfde-45b1-8c63-61b6e9b261a2",
            },
            {
              dwbuid: 3,
              subject: `${currentIndex} C Update card information`,
              updateDate: new Date(),
              type: SecureMailConversationType.Open,
              secureMailId: "10c38deb-2096-42ac-8874-587d5a64f7e0",
            },
            {
              dwbuid: 4,
              subject: `${currentIndex} D Update card information`,
              updateDate: new Date(),
              type: SecureMailConversationType.Open,
              secureMailId: "c05070c0-570f-409b-a629-3dedd1d5cc90",
            },
            {
              dwbuid: 5,
              subject: `${currentIndex} E Update card information`,
              updateDate: new Date(),
              type: SecureMailConversationType.ReadOnly,
              secureMailId: "2266964d-17e6-49d7-b8e1-4a4567e8fb3a",
            },
          ],
        }}
        selectedPageNumber={currentIndex}
        pageSize={5}
        onChangePage={setCurrentIndex}
      />
      <h3 className={styles.section}>SecureMailTable - Account</h3>
      <SecureMailTable
        className={styles.component}
        variant={"cardmember"}
        secureMail={{
          total: 50,
          secureMail: [
            {
              dwbuid: -1,
              subject: `${currentIndex} A Forgot password`,
              updateDate: new Date(),
              type: SecureMailConversationType.Open,
              secureMailId: "be921666-98c2-422e-af05-b53c116312c",
            },
            {
              dwbuid: -1,
              subject: `${currentIndex} B Login issues`,
              updateDate: new Date(),
              type: SecureMailConversationType.ReadOnly,
              secureMailId: "f164ca82-cfde-45b1-8c63-61b6e9b261a2",
            },
            {
              dwbuid: -1,
              subject: `${currentIndex} C Update card information`,
              updateDate: new Date(),
              type: SecureMailConversationType.Open,
              secureMailId: "10c38deb-2096-42ac-8874-587d5a64f7e0",
            },
            {
              dwbuid: -1,
              subject: `${currentIndex} D Update card information`,
              updateDate: new Date(),
              type: SecureMailConversationType.Open,
              secureMailId: "c05070c0-570f-409b-a629-3dedd1d5cc90",
            },
            {
              dwbuid: -1,
              subject: `${currentIndex} E Update card information`,
              updateDate: new Date(),
              type: SecureMailConversationType.ReadOnly,
              secureMailId: "2266964d-17e6-49d7-b8e1-4a4567e8fb3a",
            },
          ],
        }}
        selectedPageNumber={currentIndex}
        pageSize={5}
        onChangePage={setCurrentIndex}
      />
      <div className={styles.selectContainer}>
        <h3 className={styles.section}>Select</h3>
        <Select
          className={styles.select}
          options={[
            {label: "Option 1", value: "option1"},
            {label: "Option 2", value: "option2"},
            {label: "Option 3", value: "option3"},
          ]}
          selectedValue={selectValue}
          onChange={setSelectValue}
          placeholder="Select an option"
        />
      </div>
      <h3 className={styles.section}>Search Input</h3>
      <SearchInput
        value={searchValue}
        onChange={setSearchValue}
        prompt="Search"
      />
      <h3 className={styles.section}>TextInput</h3>
      <TextInput
        className={styles.component}
        placeholder="Username"
        value={textValue}
        onChangeText={setTextValue}
      />
    </MainLayout>
  );
}
