import instance from "lib/api/instance";

export type ISO8601String = string;

export type AutopayRouteParams = {
  partyId: string;
};

export type GetAutopayEnrollmentResponse =
  | {
      enrollmentInfo: Record<string, string>;
      enrolledDate?: ISO8601String | null;
      lastEnrollmentDate: null;
      reEnrollDate: ISO8601String;
      isAutopaySkipped: boolean;
      cancelSkipAutopayDisabledReason?: string | null;
    }
  | {
      enrollmentInfo: null;
      enrolledDate: null;
      lastEnrollmentDate?: ISO8601String | null;
      reEnrollDate: null;
      isAutopaySkipped: null;
      cancelSkipAutopayDisabledReason?: null;
    };

export function getAutopayEnrollmentOverview({
  partyId,
  dwbuid,
}: AutopayRouteParams & {
  dwbuid: string;
}): Promise<GetAutopayEnrollmentResponse> {
  return instance
    .fetch({
      path: `/staff/account/${partyId}/autopay-enrollment`,
      query: {dwbuid},
      method: "GET",
    })
    .then((res) => res.json());
}

export type EnrollAutopayRequestBody = {
  dwbuid: number;
  thirdParty?: {firstName: string; lastName: string};
  abaNumber: string;
  ddaNumber: string;
  amount:
    | {type: "MINIMUM"}
    | {type: "FULL"}
    | {type: "FIXED"; amountCents: number};
};

export function enrollAutopay({
  partyId,
  ...reqBody
}: AutopayRouteParams & EnrollAutopayRequestBody) {
  return instance.fetch({
    path: `/staff/account/${partyId}/autopay-enrollment`,
    method: "POST",
    body: JSON.stringify(reqBody),
  });
}

export type CancelAutopayEnrollmentRequestBody = {
  party: "CM" | "3RD-PARTY";
  dwbuid: number;
};

export function removeAutopayEnrollment({
  partyId,
  ...body
}: AutopayRouteParams & CancelAutopayEnrollmentRequestBody) {
  return instance.fetch({
    path: `/staff/account/${partyId}/autopay-enrollment`,
    method: "DELETE",
    body: JSON.stringify(body),
  });
}

export type SkipAutopayPaymentRequestBody = {
  party: "CM" | "3RD-PARTY";
  dwbuid: number;
};

export function skipAutopayPayment({
  partyId,
  ...body
}: AutopayRouteParams & SkipAutopayPaymentRequestBody) {
  return instance.fetch({
    path: `/staff/account/${partyId}/autopay-enrollment/skip`,
    method: "POST",
    body: JSON.stringify(body),
  });
}
