import {useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

import ActivityIndicator from "components/ActivityIndicator";
import Button from "components/Button";
import ClassificationList from "components/ClassificationList";
import SecureMailTable from "components/SecureMailTable";

import {
  useClassificationMailList,
  useGetNextMail,
} from "lib/api/react-query/secureMail";

import MainLayout from "pages/MainLayout";

import styles from "./SecureMailQueuePage.module.css";

const strings = {
  workNextMail: "Work Next Mail",
  noMessages: "No secure messsages found.",
  error: "Sorry, there was an error retrieving the secure messages.",
};

const PAGE_SIZE = 10;

export default function SecureMailQueuePage() {
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState(0);
  const {mutateAsync: submitGetNextMail, isLoading: isNextMailLoading} =
    useGetNextMail();

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedClassificationId = parseInt(
    searchParams.get("classification") || "0",
  );

  const {
    data: secureMail,
    isLoading: isLoadingSecureMail,
    error,
  } = useClassificationMailList({
    classificationId: selectedClassificationId,
    pageNumber: selectedPage,
    pageSize: PAGE_SIZE,
  });

  return (
    <MainLayout
      renderLeftContent={() => (
        <ClassificationList
          selected={selectedClassificationId}
          onChange={(id) => {
            setSelectedPage(0); // Reset page to start of next classification list
            setSearchParams({classification: id.toString()});
          }}
        />
      )}
    >
      {secureMail && secureMail.total !== 0 ? (
        <Button
          variant="unfilled"
          loading={isNextMailLoading}
          onClick={() => {
            submitGetNextMail({id: selectedClassificationId})
              .then((response) => {
                if (response.mailId === null) {
                  return;
                }
                navigate({
                  pathname: `/secure-mail/${response.mailId}`,
                  search: `classification=${selectedClassificationId}`,
                });
              })
              .catch(() => {
                alert("There was a problem getting the next mail.");
              });
          }}
          className={styles.nextButton}
        >
          {strings.workNextMail}
        </Button>
      ) : null}
      {secureMail && secureMail.total !== 0 ? (
        <SecureMailTable
          className={styles.table}
          variant={"classification"}
          secureMail={secureMail}
          selectedPageNumber={selectedPage}
          pageSize={PAGE_SIZE}
          onChangePage={setSelectedPage}
          onClickRow={(id) =>
            navigate({
              pathname: `/secure-mail/${id}`,
              search: `classification=${selectedClassificationId}`,
            })
          }
        />
      ) : isLoadingSecureMail ? (
        <div className={styles.container}>
          <ActivityIndicator />
        </div>
      ) : (
        <div className={styles.container}>
          {error ? strings.error : strings.noMessages}
        </div>
      )}
    </MainLayout>
  );
}
