import React, {
  createContext,
  createElement,
  useContext,
  useEffect,
  useState,
} from "react";

import {getStaffAccount, login, logout, StaffAccountRole} from "lib/api/auth";

export type AuthenticatedUser = {
  roles: StaffAccountRole[];
};
type SignOutMethod = "SIGN_OUT" | "SESSION_EXPIRED" | "NO_SESSION";
export type AuthenticationState =
  | {
      user: AuthenticatedUser;
    }
  | {
      user: null;
      signOutMethod: SignOutMethod | null;
    };

const __AUTH = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  _setAuthenticationState: (authState: AuthenticationState) => {},
};

export const ERR_CODE_NOT_AUTHORIZED_EXCEPTION = "NotAuthorizedException";

export function getAuthenticatedUser(): Promise<AuthenticationState> {
  return getStaffAccount()
    .then(({roles}) => {
      __AUTH._setAuthenticationState({user: {roles}});
      return {
        user: {roles},
      };
    })
    .catch((err) => {
      throw err;
    });
}

export function signIn(
  username: string,
  password: string,
): Promise<{
  user: AuthenticatedUser;
  result: "SUCCESS";
}> {
  return login({username, password})
    .then(getStaffAccount)
    .then(({roles}) => {
      __AUTH._setAuthenticationState({user: {roles}});
      return {
        user: {roles},
        result: "SUCCESS",
      };
    });
}

function _useAuthenticationState(): [AuthenticationState, boolean] {
  const [authState, setAuthState] = useState<AuthenticationState>({
    user: null,
    signOutMethod: null,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    __AUTH._setAuthenticationState = (nextAuthState: AuthenticationState) => {
      setAuthState((prevAuthState) => {
        if (prevAuthState && nextAuthState) {
          let nextSignOutMethod: SignOutMethod | null = nextAuthState.user
            ? null
            : nextAuthState.signOutMethod;
          if (
            !prevAuthState.user &&
            !nextAuthState.user &&
            nextAuthState.signOutMethod === "SESSION_EXPIRED"
          ) {
            nextSignOutMethod = "NO_SESSION";
          }
          return {
            ...nextAuthState,
            signOutMethod: nextSignOutMethod,
          };
        }
        return nextAuthState;
      });
    };

    getAuthenticatedUser()
      .then((nextAuthState) => {
        setAuthState(nextAuthState);
        setLoading(false);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error("Error getting currentAuthenticatedUser", err);
        setLoading(false);
      });
  }, []);

  return [authState, loading];
}

const AuthContext = createContext<[AuthenticationState, boolean] | undefined>(
  undefined,
);

export async function signOut() {
  return logout().then(() => {
    __AUTH._setAuthenticationState({user: null, signOutMethod: "SIGN_OUT"});
  });
}

export function clearAuth() {
  __AUTH._setAuthenticationState({
    user: null,
    signOutMethod: "SESSION_EXPIRED",
  });
}

/**
 * useAuthenticationState returns the auth state and a loading boolean.
 * Best when used with `<Provider>`.
 *
 * Example usage:
 * ```typescript
 * const [authenticationState, loadingAuth] = useAuthenticationState();
 * ```
 *
 * See also: useAuthenticatedUser if only the user is needed
 */
export function useAuthenticationState() {
  const context = useContext(AuthContext);
  if (context) return context;
  return _useAuthenticationState();
}

// NOTE: this may be exported if needed elsewhere
function useAuthenticatedUser(): AuthenticatedUser | null {
  const [{user}] = useAuthenticationState();
  return user;
}

export function Provider({children}: {children: React.ReactNode}) {
  const authenticationState = useAuthenticationState();
  return createElement(
    AuthContext.Provider,
    {value: authenticationState},
    children,
  );
}

function useIsAuthenticatedAndSupportsRoles(
  desiredRoles: StaffAccountRole[],
): boolean {
  const user = useAuthenticatedUser();
  if (!user) {
    return false;
  }
  return user.roles.some((role) => {
    for (let i = 0; i < desiredRoles.length; i++) {
      if (desiredRoles[i] === role) {
        return true;
      }
    }
    return false;
  });
}

export function useIsAgentRoleACH(): boolean {
  return useIsAuthenticatedAndSupportsRoles(["cs-ach"]);
}

export function useIsAgentRoleUSA(): boolean {
  return useIsAuthenticatedAndSupportsRoles(["cs-usa"]);
}

export function useIsAgentRoleUsername(): boolean {
  return useIsAuthenticatedAndSupportsRoles(["cs-username"]);
}

export function useIsAgentRoleUnlock(): boolean {
  return useIsAuthenticatedAndSupportsRoles(["cs-unlock"]);
}
