import classNames from "classnames";
import {Link, LinkProps} from "react-router-dom";

import ActivityIndicator from "components/ActivityIndicator";

import styles from "./Button.module.css";

type Props = {
  className?: string;
  labelClassName?: string;
  disabled?: boolean;
  loading?: boolean;
  variant: "filled" | "unfilled";
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  children: string;
} & (
  | {
      to: string;
      state?: LinkProps["state"];
      onClick?: React.MouseEventHandler<HTMLAnchorElement>;
    }
  | {to?: never; onClick?: React.MouseEventHandler<HTMLButtonElement>}
);

export default function Button(props: Props) {
  if (props.to && !props.disabled && !props.loading) {
    return (
      <Link
        className={classNames(
          styles.button,
          props.variant === "filled" ? styles.filled : styles.unfilled,
          props.className,
        )}
        to={props.to}
        state={props.state}
        onClick={props.onClick}
      >
        <span className={classNames(styles.label, props.labelClassName)}>
          {props.children}
        </span>
      </Link>
    );
  } else {
    return (
      <button
        className={classNames(
          styles.button,
          props.variant === "filled" ? styles.filled : styles.unfilled,
          props.loading ? styles.loading : styles.idle,
          props.className,
        )}
        disabled={props.disabled || props.loading}
        onClick={props.onClick}
      >
        <span className={props.labelClassName}>
          {props.loading ? (
            <ActivityIndicator
              color={props.variant === "filled" ? "#e1e1e8" : undefined}
            />
          ) : (
            props.children
          )}
        </span>
      </button>
    );
  }
}
