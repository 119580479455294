import instance from "lib/api/instance";

export type ClassificationsResponse = {
  id: number;
  description: string;
};

export function getClassifications(): Promise<{
  classifications: ClassificationsResponse[];
}> {
  return instance
    .fetch({path: "/staff/classifications", method: "GET"})
    .then((res) => res.json());
}

export type ClassificationCountResponse = {
  id: number;
  description: string;
  count: number;
};

export function getClassificationCount(): Promise<{
  classifications: ClassificationCountResponse[];
}> {
  return instance
    .fetch({path: "/staff/classification-count", method: "GET"})
    .then((res) => res.json());
}
