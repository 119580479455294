import classNames from "classnames";

import styles from "./DateInput.module.css";

export type DateString = `${number}-${number}-${number}` | "";

type DateInputProps = {
  className?: string;
  label: string;
  value: DateString;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDate?: (date: DateString) => void;
};

export default function DateInput(props: DateInputProps) {
  return (
    <div className={classNames(props.className, styles.container)}>
      <label className={styles.label}>{props.label}</label>
      <input
        className={styles.input}
        type="date"
        value={props.value}
        onChange={(event) => {
          props.onChange?.(event);
          props.onChangeDate?.(event.target.value as DateString);
        }}
        disabled={!!props.disabled}
      />
    </div>
  );
}
