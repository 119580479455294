// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
const dollarStrFormat = /\B(?=(\d{3})+(?!\d))/g;

export const centsToDollarString: (centsAmount: number) => string = (
  centsAmount: number,
) => {
  let centsAmountStr = centsAmount.toString();
  if (centsAmountStr.length === 0 || Number.isNaN(centsAmount)) {
    return "";
  }
  let prefix = "";
  if (centsAmount < 0) {
    centsAmountStr = centsAmountStr.slice(1);
    prefix = "-";
  }
  const dollarStr =
    centsAmountStr.length > 2 ? centsAmountStr.slice(0, -2) : "0";
  const formattedDollarStr = dollarStr.replace(dollarStrFormat, ",");
  const centsStr =
    centsAmountStr.length > 2
      ? centsAmountStr.slice(-2)
      : centsAmountStr.padStart(2, "0");
  return `${prefix}$${formattedDollarStr}.${centsStr}`;
};

export const dollarStringToCents: (dollarStr: string) => number = (
  dollarStr: string,
) => {
  if (!dollarStr) {
    return 0;
  }

  if (RegExp(/[^0-9.,$]/g).test(dollarStr)) {
    throw Error(
      `dollarStr '${dollarStr}' contains non-digit, non-decimal, non-comma, non-dollar-sign characters`,
    );
  }

  if (!RegExp(/[0-9]/g).test(dollarStr)) {
    return 0;
  }

  const arr = dollarStr.split(".");
  if (arr.length > 2) {
    throw Error(`dollarStr '${dollarStr}' contains more than one decimal`);
  }

  const integerPart = arr[0]
    ? Number.parseInt(arr[0].replace(/\D/g, ""), 10)
    : 0;
  const fractionalPart = arr[1]
    ? parseInt(arr[1].slice(0, 2).padEnd(2, "0"), 10)
    : 0;

  return integerPart * 100 + fractionalPart;
};

export function getEmployeeInitials(
  employeeFirstName: string | null | undefined,
  employeeLastName: string | null | undefined,
): string {
  if (employeeFirstName || employeeLastName) {
    let initialsToReturn = "";
    if (employeeFirstName) {
      initialsToReturn = employeeFirstName.charAt(0);
    }
    if (employeeLastName) {
      initialsToReturn += employeeLastName.charAt(0);
    }
    return initialsToReturn;
  }
  return "1FB";
}

export function getEmployeeFullName(
  isEmployee: boolean,
  employeeFirstName: string | null | undefined,
  employeeLastName: string | null | undefined,
): string | undefined {
  if (isEmployee) {
    if (employeeFirstName || employeeLastName) {
      let fullNameToReturn = "";
      if (employeeFirstName) {
        fullNameToReturn = employeeFirstName;
        if (employeeLastName) {
          fullNameToReturn += " ";
        }
      }
      if (employeeLastName) {
        fullNameToReturn += employeeLastName;
      }
      return fullNameToReturn;
    }
  }
  return undefined;
}
