import classNames from "classnames";
import PaymentAmountSelectionButton, {
  PaymentAmountSelectionButtonProps,
} from "components/PaymentAmountSelectionButton";
import {useEffect, useRef} from "react";
import styles from "./FixedPaymentAmountSelectionButton.module.css";

const strings = {
  placeholder: "$0.00",
  title: "Fixed Payment Amount",
};

type Props = Omit<PaymentAmountSelectionButtonProps, "title"> & {
  value: string;
  onChangeText: (val: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  invalidMessage?: string;
};
export default function FixedPaymentAmountSelectionButton({
  className,
  isSelected,
  onClick,
  invalid,
  invalidMessage,
  value,
  onChangeText,
  onBlur,
  onFocus,
}: Props) {
  const customAmountInputRef = useRef<HTMLInputElement>(null);
  const showInvalid = invalid || !!invalidMessage;

  useEffect(() => {
    if (isSelected) {
      customAmountInputRef.current?.focus();
    }
  }, [isSelected]);

  return (
    <PaymentAmountSelectionButton
      isSelected={isSelected}
      onClick={onClick}
      title={strings.title}
      invalid={invalid}
      invalidMessage={invalidMessage}
      className={classNames(className, styles.transition, {
        [styles.childrenHeight]: isSelected,
      })}
    >
      <div className={styles.container}>
        <input
          className={classNames(styles.customInput, {
            [styles.invalidCustomInput]: showInvalid,
          })}
          ref={customAmountInputRef}
          value={value}
          onChange={(e) => onChangeText(e.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={strings.placeholder}
        />
      </div>
    </PaymentAmountSelectionButton>
  );
}
