import {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import ActivityIndicator from "components/ActivityIndicator";
import AlertModal from "components/AlertModal";
import Button from "components/Button";
import MessageBubble from "components/MessageBubble";
import MessageInput from "components/MessageInput";
import MessagesHeader from "components/MessagesHeader";
import Select from "components/Select";

import {FetchError} from "lib/api/instance";
import {useGetClassifications} from "lib/api/react-query/classification";
import {
  useAddToThreadMutation,
  useGetNextMail,
  useGetSecureMailThread,
  usePatchSecureMailClassificationMutation,
  useUpdateSecureMailStatusMutation,
  useViewMailAttachmentMutation,
} from "lib/api/react-query/secureMail";
import {
  INSTANT_CARD_CLASSIFICATION_ID,
  SecureMailConversationType,
  UpdateSecureMailStatusPath,
  UpdateSecureMailStatusRequest,
} from "lib/api/secureMail";

import MainLayout from "pages/MainLayout";

import {useGetAccountQuery} from "lib/api/react-query/account";
import styles from "./SecureMailMessagingPage.module.css";

enum Sender {
  OneFbEmployee = "1FB",
}

const strings = {
  updateStatusError: "We were unable to close this Secure Mail at this time.",
  tryAgain: "Please try again.",
  save: "Save",
  saved: "Saved!",
  reopened: "Reopened!",
};

type AssignedClassificationProps = {
  selectedClassification: number;
  setSelectedClassification: (newClassification: number) => void;
  secureMailClassification: number;
  setSecureMailClassification: (newClassification: number) => void;
  isSecureMailLoading: boolean;
  currentStatus?: SecureMailConversationType;
};

function AssignedClassification(props: AssignedClassificationProps) {
  const [savedTextVisible, setSavedTextVisibility] = useState<boolean>(false);
  const [reopenedTextVisible, setReopenedTextVisibility] =
    useState<boolean>(false);

  const {data: classificationsList, isLoading: isClassificationsListLoading} =
    useGetClassifications();

  const {id} = useParams();

  const {mutateAsync: updateSecureMailStatus, isLoading: isUpdatingStatus} =
    useUpdateSecureMailStatusMutation();

  const isOpen = props.currentStatus !== SecureMailConversationType.ReadOnly;
  const showClassificationSelectorAndSaveButton =
    props.secureMailClassification !== INSTANT_CARD_CLASSIFICATION_ID;

  const isLoading =
    props.isSecureMailLoading ||
    isUpdatingStatus ||
    isClassificationsListLoading;

  const {
    mutateAsync: updateMailClassification,
    isLoading: isUpdateMailClassificationLoading,
  } = usePatchSecureMailClassificationMutation();

  useEffect(() => {
    setSavedTextVisibility(true);
  }, [props.secureMailClassification]);

  useEffect(() => {
    setSavedTextVisibility(false);
  }, [props.selectedClassification]);

  if (isLoading) {
    return (
      <div className={styles.assignedClassification}>
        <ActivityIndicator className={styles.centeredActivityIndicator} />
      </div>
    );
  }

  const makeUpdateStatusRequest = (newStatus: SecureMailConversationType) => {
    const openSecureMailStatusRequest: UpdateSecureMailStatusRequest &
      UpdateSecureMailStatusPath = {
      id: id || "",
      type: newStatus,
    };

    updateSecureMailStatus(openSecureMailStatusRequest)
      .then(() => {
        setReopenedTextVisibility(
          newStatus === SecureMailConversationType.Open,
        );
      })
      .catch((err: FetchError) => {
        alert(
          `${strings.updateStatusError} ${err.message} ${strings.tryAgain}`,
        );
      });
  };

  return (
    <div className={styles.assignedClassification}>
      <span className={styles.classificationText}>
        {isOpen ? "Assigned Classification" : "Closed Mail"}
      </span>
      {reopenedTextVisible ? (
        <span className={styles.classificationText}>{strings.reopened}</span>
      ) : null}
      {showClassificationSelectorAndSaveButton ? (
        <>
          <Select
            options={Object.values(
              classificationsList?.classifications ?? {},
            ).map((classification) => ({
              label: classification.description,
              value: String(classification.id),
            }))}
            selectedValue={String(props.selectedClassification)}
            onChange={(selectedId) =>
              props.setSelectedClassification(parseInt(selectedId))
            }
            disabled={!isOpen}
          />
          <Button
            className={styles.saveButton}
            variant="unfilled"
            disabled={
              !isOpen ||
              props.selectedClassification === props.secureMailClassification
            }
            onClick={() => {
              if (id) {
                updateMailClassification({
                  mailId: id,
                  classificationId: props.selectedClassification,
                })
                  .then(() => {
                    props.setSecureMailClassification(
                      props.selectedClassification,
                    );
                  })
                  .catch(() => {
                    alert(
                      "There was a problem updating the mail classification.",
                    );
                  });
              }
            }}
            loading={isUpdateMailClassificationLoading}
          >
            {savedTextVisible ? strings.saved : strings.save}
          </Button>

          <Button
            variant="filled"
            onClick={() => {
              if (!id) {
                return;
              }

              if (isOpen) {
                makeUpdateStatusRequest(SecureMailConversationType.ReadOnly);
                return;
              }

              makeUpdateStatusRequest(SecureMailConversationType.Open);
            }}
            loading={isUpdatingStatus}
          >
            {isOpen ? "Close Mail" : "Reopen Mail"}
          </Button>
        </>
      ) : null}
    </div>
  );
}

export default function SecureMailMessagingPage() {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [messageAttachments, setMessageAttachments] = useState<
    File[] | undefined
  >();
  const [selectedClassification, setSelectedClassification] = useState(0);
  const [secureMailClassification, setSecureMailClassification] = useState(0);
  const {mutateAsync: submitGetNextMail, isLoading: isNextMailLoading} =
    useGetNextMail();
  const {id} = useParams();
  const {data: secureMail, isLoading: isSecureMailLoading} =
    useGetSecureMailThread(id ?? "", !!id);
  const {mutateAsync: sendMessage, isLoading: isSendMessageLoading} =
    useAddToThreadMutation();
  const {mutateAsync: updateSecureMailStatus} =
    useUpdateSecureMailStatusMutation();

  const {mutateAsync: getAttachment, isLoading: isAttachmentLoading} =
    useViewMailAttachmentMutation();
  const {data: account, isFetching: isGetAccountFetching} = useGetAccountQuery({
    dwbuid: String(secureMail?.dwbuid ?? ""),
  });
  const customerFirstName = isGetAccountFetching
    ? "Loading..."
    : account
    ? `${account.firstName}`
    : "N/A";
  const customerLastName = isGetAccountFetching
    ? ""
    : account
    ? `${account.lastName}`
    : "";

  const [alertModalShown, setAlertModalShown] = useState(false);
  const endOfMessages = useRef<HTMLDivElement>(null);
  const canSendMessage =
    id && (message.trim() !== "" || messageAttachments !== undefined);
  const pathname = useLocation().pathname;
  const search = useLocation().search;
  const isSecureMailQueue =
    pathname.startsWith("/secure-mail") && !search.includes("account");

  const scrollToBottom = () => {
    if (endOfMessages.current) {
      endOfMessages.current.scrollIntoView();
    }
  };
  const onClickSend = () => {
    if (!canSendMessage) {
      return;
    }
    const messageBody = message;
    sendMessage({
      secureMailId: id,
      messageBody,
      file:
        messageAttachments && messageAttachments.length > 0
          ? messageAttachments[0]
          : undefined,
    })
      .then(() => {
        setMessage("");
        setMessageAttachments(undefined);
      })
      .catch(() => {
        alert("There was a problem sending the message.");
      });
  };

  useEffect(() => {
    if (secureMail) {
      setSelectedClassification(secureMail.classificationId);
      setSecureMailClassification(secureMail.classificationId);
    }
    scrollToBottom();
  }, [secureMail]);

  useEffect(() => {
    // Upon visiting this page, if status is "NEW", update status to "OPEN"
    if (secureMail && secureMail.type === SecureMailConversationType.New) {
      updateSecureMailStatus({
        id: id || "",
        type: SecureMailConversationType.Open,
      });
    }
  }, [id, secureMail, updateSecureMailStatus]);

  return (
    <MainLayout
      renderLeftContent={() => (
        <AssignedClassification
          selectedClassification={selectedClassification}
          setSelectedClassification={setSelectedClassification}
          secureMailClassification={secureMailClassification}
          setSecureMailClassification={setSecureMailClassification}
          isSecureMailLoading={isSecureMailLoading}
          currentStatus={secureMail?.type}
        />
      )}
      onClickBack={isSecureMailQueue ? undefined : () => navigate(-1)}
      forwardButton={
        isSecureMailQueue
          ? {
              label: "Next Mail",
              loading: isNextMailLoading,
              onClick: () => {
                submitGetNextMail({
                  id: selectedClassification,
                  mailId: id,
                })
                  .then((response) => {
                    navigate(
                      response.mailId === null
                        ? `/secure-mail`
                        : `../${response.mailId}`,
                    );
                  })
                  .catch(() => {
                    alert("There was a problem getting the next mail.");
                  });
              },
            }
          : undefined
      }
    >
      <div className={styles.messagingContainer}>
        <MessagesHeader
          className={styles.messagesHeader}
          subject={secureMail?.subject}
          dwbuid={secureMail?.dwbuid}
          employees={secureMail?.employees}
          customerFirstName={customerFirstName}
          customerLastName={customerLastName}
          isRequestLoading={isSecureMailLoading}
        />
        <div className={styles.spacing} />
        <div className={styles.scrollableSection}>
          {secureMail?.messages.map((messageElem) => {
            return (
              <MessageBubble
                key={messageElem.messageId}
                className={styles.messageBubble}
                timestamp={messageElem.createDate}
                message={messageElem.body}
                dwbuid={messageElem.dwbuid}
                isEmployee={messageElem.sender === Sender.OneFbEmployee}
                employeeFirstName={messageElem.employeeFirstName}
                employeeLastName={messageElem.employeeLastName}
                customerFirstName={customerFirstName}
                customerLastName={customerLastName}
                attachments={messageElem.attachments}
                attachmentButtonDisabled={isAttachmentLoading}
                onAttachmentClick={(attachmentId) => {
                  if (!id) {
                    return;
                  }
                  getAttachment({
                    mailId: id,
                    messageId: messageElem.messageId,
                    attachmentId: attachmentId,
                  }).then((response) => {
                    const newWindow = window.open(response.url, "_blank");
                    if (
                      !newWindow ||
                      newWindow.closed ||
                      typeof newWindow.closed === "undefined"
                    ) {
                      setAlertModalShown(true);
                    }
                  });
                }}
                timeRead={messageElem.timeRead}
              />
            );
          })}
          <div className={styles.bottomSpace} ref={endOfMessages} />
        </div>
        {secureMail &&
        secureMail.type !== SecureMailConversationType.ReadOnly ? (
          <MessageInput
            message={message}
            attachments={messageAttachments}
            onChangeMessage={setMessage}
            onSend={onClickSend}
            onAttachment={setMessageAttachments}
            maxAttachments={1}
            isSendMessageLoading={isSendMessageLoading}
          />
        ) : null}
      </div>
      <AlertModal
        isOpen={alertModalShown}
        title="Popup Blocked"
        message="Please allow popups to be able to view attachments."
        onClickConfirm={() => {
          setAlertModalShown(false);
        }}
      />
    </MainLayout>
  );
}
