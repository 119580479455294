/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";

import Button from "components/Button";

import styles from "./ErrorBoundary.module.css";

export default class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {error: undefined};
  }

  static getDerivedStateFromError(error: any) {
    return {error: error};
  }

  render() {
    if (this.state.error) {
      return (
        <div className={styles.container}>
          <div className={styles.infoContainer}>
            <span className={styles.title}>Something went wrong.</span>
            <code className={styles.errorMessage}>
              {this.state.error.toString()}
            </code>
            <Button
              className={styles.button}
              variant="filled"
              onClick={() => location.reload()}
            >
              Refresh
            </Button>
            <Button
              className={styles.button}
              variant="unfilled"
              to="/"
              onClick={() => this.setState({error: undefined})}
            >
              Home
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
