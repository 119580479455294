import {stringify} from "query-string";

import {clearAuth} from "lib/auth";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FetchError<TErrorData = any> extends Error {
  response?: Response;
  responseJson?: TErrorData;
}

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

type HTTPRequestMethod =
  | "GET"
  | "HEAD"
  | "POST"
  | "PATCH"
  | "PUT"
  | "DELETE"
  | "CONNECT"
  | "OPTIONS";

export default {
  fetch: (
    options: {
      /** The relative path of the request. (Do not include base url) */
      path: string;
      /** An object containing the query search params. */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      query?: Record<string, any>;
    } & RequestInit & {method: HTTPRequestMethod},
  ): Promise<Response> => {
    const {path, query, ...requestInit} = options;
    const queryPart = query ? `?${stringify(query)}` : "";

    return fetch(`${API_BASE_URL}${path}${queryPart}`, requestInit).then(
      (response) => {
        if (!response.ok) {
          if (response.status === 401) {
            clearAuth();
          }

          const error: FetchError = Error(
            `fetch http status ${response.status}`,
          );
          error.response = response;
          error.responseJson = undefined;
          return response
            .json()
            .then((responseJson) => {
              error.responseJson = responseJson;
              return Promise.reject(error);
            })
            .catch(() => {
              return Promise.reject(error);
            });
        }
        return response;
      },
    );
  },
};
