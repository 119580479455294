import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

import {
  AgentPortalUseMutationOptions,
  AgentPortalUseQueryOptions,
} from "lib/api/react-query/base";
import {
  addToThread,
  CardmemberMailList,
  CardmemberMailListQueryParams,
  ClassificationMailListResponse,
  ClassificationMailRequest,
  composeSecureMail,
  getCardmemberMailList,
  getClassificationMailList,
  getNextMail,
  getSecureMailThread,
  GetSecureMailThreadResponse,
  patchSecureMailClassification,
  updateSecureMailStatus,
  viewMailAttachment,
} from "lib/api/secureMail";

export function useGetNextMail() {
  return useMutation(getNextMail);
}

export function useCardmemberMailList(
  id: number, // partyId
  query: CardmemberMailListQueryParams,
  options?: AgentPortalUseQueryOptions<CardmemberMailList>,
) {
  return useQuery(
    ["cardmemberMailList", id, query],
    () => getCardmemberMailList(id, query),
    options,
  );
}

export function useGetSecureMailThread(
  mailId: string,
  enabled: boolean,
  options?: AgentPortalUseQueryOptions<GetSecureMailThreadResponse<Date>>,
) {
  return useQuery(
    ["secureMailThread", mailId],
    () => getSecureMailThread(mailId),
    {enabled: enabled, ...options},
  );
}

export function useClassificationMailList(
  req: ClassificationMailRequest,
  options?: AgentPortalUseQueryOptions<ClassificationMailListResponse<Date>>,
) {
  return useQuery(
    [
      "classificationMailList",
      req.classificationId,
      req.pageNumber,
      req.pageSize,
    ],
    () => getClassificationMailList(req),
    options,
  );
}

export function usePatchSecureMailClassificationMutation(
  options?: AgentPortalUseMutationOptions,
) {
  return useMutation(patchSecureMailClassification, options);
}

export function useViewMailAttachmentMutation(
  options?: AgentPortalUseMutationOptions,
) {
  return useMutation(viewMailAttachment, options);
}

export function useAddToThreadMutation(
  options?: AgentPortalUseMutationOptions,
) {
  const queryClient = useQueryClient();
  return useMutation(addToThread, {
    ...options,
    onSuccess: (_, req) =>
      queryClient.invalidateQueries(["secureMailThread", req.secureMailId]),
  });
}

export function useUpdateSecureMailStatusMutation(
  options?: AgentPortalUseMutationOptions,
) {
  const queryClient = useQueryClient();
  return useMutation(updateSecureMailStatus, {
    ...options,
    onSuccess: (_, req) =>
      queryClient.invalidateQueries(["secureMailThread", req.id]),
  });
}

export function useComposeSecureMailMutation(
  options?: AgentPortalUseMutationOptions,
) {
  return useMutation(composeSecureMail, options);
}
