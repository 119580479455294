import instance from "lib/api/instance";
import {formatInCentralTime} from "lib/formaInCentralTime";

export type Attachment = {
  attachmentId: string;
  name: string;
};

export type Employee = {
  employeeId: number;
  firstname: string;
  lastname: string;
};

export type Message<DateType> = {
  messageId: string;
  sender: string;
  body: string;
  createDate: DateType;
  dwbuid: number;
  employeeId: number | null;
  employeeFirstName: string | null;
  employeeLastName: string | null;
  actionId: number;
  attachments: Attachment[];
  timeRead: DateType | null;
};

export enum SecureMailConversationType {
  New = "NEW",
  Open = "OPEN",
  ReadOnly = "READONLY",
}
export type GetSecureMailThreadResponse<DateType> = {
  id: string;
  dwbuid: number;
  subject: string;
  type: SecureMailConversationType;
  classificationId: number;
  messages: Message<DateType>[];
  employees: Employee[];
};

export const INSTANT_CARD_CLASSIFICATION_ID = 48;

export function getSecureMailThread(
  id: string,
): Promise<GetSecureMailThreadResponse<Date>> {
  return instance
    .fetch({path: `/staff/secure-mail/${id}`, method: "GET"})
    .then((res) => res.json())
    .then((json: GetSecureMailThreadResponse<string>) => {
      return {
        ...json,
        messages: json.messages.map((message) => ({
          ...message,
          createDate: new Date(message.createDate),
          timeRead: message.timeRead ? new Date(message.timeRead) : null,
        })),
      };
    });
}

export type ClassificationMailResponse<DateType> = {
  dwbuid: number;
  subject: string;
  updateDate: DateType;
  type: string;
  secureMailId: string;
};

export type ClassificationMailListResponse<DateType> = {
  total: number;
  secureMail: ClassificationMailResponse<DateType>[];
};

export type ClassificationMailRequest = {
  classificationId: number;
  pageNumber: number;
  pageSize: number;
};

export function getClassificationMailList(
  req: ClassificationMailRequest,
): Promise<ClassificationMailListResponse<Date>> {
  return instance
    .fetch({
      path: `/staff/secure-mail/classification/${req.classificationId}`,
      method: "GET",
      query: {page: req.pageNumber, size: req.pageSize},
    })
    .then((res) => res.json())
    .then((res: ClassificationMailListResponse<string>) => {
      return {
        ...res,
        secureMail:
          res.secureMail?.map((secureMail) => {
            return {...secureMail, updateDate: new Date(secureMail.updateDate)};
          }) ?? [],
      };
    });
}

export type AddToThreadPath = {
  id: string;
};

export type AddToThreadRequest = {
  secureMailId: string;
  messageBody?: string;
  file?: File;
};

export function addToThread(req: AddToThreadRequest) {
  const data = new FormData();
  if (req.messageBody) {
    data.append("body", req.messageBody);
  }
  if (req.file) {
    data.append("file", req.file, req.file.name);
  }

  return instance.fetch({
    path: `/staff/secure-mail/${req.secureMailId}`,
    method: "POST",
    body: data,
  });
}

export type NextClassificationMailRequest = {
  mailId?: string;
};

export type NextClassificationMailPath = {
  id: number;
};

export type NextClassificationMailResponse = {
  mailId: string | null;
};

export function getNextMail(
  req: NextClassificationMailPath & NextClassificationMailRequest,
): Promise<NextClassificationMailResponse> {
  return instance
    .fetch({
      path: `/staff/secure-mail/classification/${req.id}`,
      method: "PATCH",
      body: JSON.stringify({mailId: req.mailId ?? null}),
    })
    .then((res) => res.json());
}

export type UpdateSecureMailStatusRequest = {
  type: SecureMailConversationType;
};

export type UpdateSecureMailStatusPath = {
  id: string;
};

export function updateSecureMailStatus(
  req: UpdateSecureMailStatusRequest & UpdateSecureMailStatusPath,
) {
  return instance.fetch({
    path: `/staff/secure-mail/${req.id}/status`,
    method: "PATCH",
    body: JSON.stringify({type: req.type}),
  });
}

export type ViewMailAttachmentRequest = {
  mailId: string;
  messageId: string;
  attachmentId: string;
};

export type ViewMailAttachmentResponse = {
  url: string;
};

export function viewMailAttachment(
  req: ViewMailAttachmentRequest,
): Promise<ViewMailAttachmentResponse> {
  return instance
    .fetch({
      path: `/staff/secure-mail/${req.mailId}/message/${req.messageId}/attachment/${req.attachmentId}`,
      method: "GET",
    })
    .then((res) => res.json());
}

export type CardmemberMailResponse = {
  subject: string;
  updateDate: string;
  type: string;
  secureMailId: string;
};

export type CardmemberMailListResponse = {
  total: number;
  secureMail: CardmemberMailResponse[];
};

export type CardmemberMail = {
  subject: string;
  updateDate: Date;
  type: string;
  secureMailId: string;
};

export type CardmemberMailList = {
  total: number;
  secureMail: CardmemberMail[];
};

export type CardmemberMailListQueryParams = {
  page: number;
  size: number;
  start?: Date;
  end?: Date;
};

export function getCardmemberMailList(
  id: number,
  query: CardmemberMailListQueryParams,
): Promise<CardmemberMailList> {
  return instance
    .fetch({
      path: `/staff/secure-mail/card-member/${id}`,
      query: {
        ...query,
        start: query.start
          ? formatInCentralTime(query.start, "yyyy-MM-dd")
          : "",
        end: query.end ? formatInCentralTime(query.end, "yyyy-MM-dd") : "",
      },
      method: "GET",
    })
    .then((res) => res.json())
    .then((res: CardmemberMailListResponse) => {
      return {
        ...res,
        secureMail:
          res.secureMail?.map((secureMail) => {
            return {...secureMail, updateDate: new Date(secureMail.updateDate)};
          }) ?? [],
      };
    });
}

export type PatchSecureMailClassificationRequest = {
  mailId: string;
  classificationId: number;
};

export function patchSecureMailClassification(
  req: PatchSecureMailClassificationRequest,
) {
  return instance.fetch({
    path: `/staff/secure-mail/${req.mailId}/classification`,
    method: "PATCH",
    body: JSON.stringify({classificationId: req.classificationId}),
  });
}

export type ComposeSecureMailRequest = {
  partyId: number;
  subject: string;
  classificationId: number;
  status: SecureMailConversationType;
  body?: string;
  file?: File;
};

export type ComposeSecureMailResponse = {
  id: string;
  messageId: string;
  attachmentId: string;
};

export function composeSecureMail(
  req: ComposeSecureMailRequest,
): Promise<ComposeSecureMailResponse> {
  const data = new FormData();
  data.append("subject", req.subject);
  data.append("classificationId", String(req.classificationId));
  data.append("status", req.status);
  if (req.body) {
    data.append("body", req.body);
  }
  if (req.file) {
    data.append("file", req.file, req.file.name);
  }

  return instance
    .fetch({
      path: `/staff/secure-mail/${req.partyId}/compose`,
      method: "POST",
      body: data,
    })
    .then((res) => res.json());
}
