import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

import {
  AutopayRouteParams,
  enrollAutopay,
  getAutopayEnrollmentOverview,
  removeAutopayEnrollment,
  skipAutopayPayment,
} from "lib/api/autopay";
import {AgentPortalUseMutationOptions} from "lib/api/react-query/base";

export function useGetAutopayEnrollmentOverview({
  partyId,
  dwbuid,
}: AutopayRouteParams & {dwbuid: string}) {
  return useQuery({
    queryKey: ["autopayEnrollmentOverview", partyId],
    queryFn: () => getAutopayEnrollmentOverview({partyId, dwbuid}),
    enabled: !!partyId,
  });
}

export function useEnrollAutopayMutation(
  options?: AgentPortalUseMutationOptions,
) {
  const queryClient = useQueryClient();
  return useMutation(enrollAutopay, {
    ...options,
    onSuccess: (_, req) => {
      queryClient.invalidateQueries(["autopayEnrollmentOverview", req.partyId]);
    },
  });
}

export function useRemoveAutopayEnrollment(
  options?: AgentPortalUseMutationOptions,
) {
  const queryClient = useQueryClient();
  return useMutation(removeAutopayEnrollment, {
    ...options,
    onSuccess: (_, req) => {
      queryClient.invalidateQueries(["autopayEnrollmentOverview", req.partyId]);
    },
  });
}

export function useSkipAutopayPayment(options?: AgentPortalUseMutationOptions) {
  const queryClient = useQueryClient();
  return useMutation(skipAutopayPayment, {
    ...options,
    onSuccess: (_, req) => {
      queryClient.invalidateQueries(["autopayEnrollmentOverview", req.partyId]);
    },
  });
}
