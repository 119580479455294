import lockedIcon from "assets/images/locked-icon.svg";
import unlockedIcon from "assets/images/unlocked-icon.svg";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import ActivityIndicator from "components/ActivityIndicator";
import Button from "components/Button";
import {formatInCentralTime} from "lib/formaInCentralTime";

import {FetchError} from "lib/api/instance";
import {
  useChangeUsernameMutation,
  useGetAccountDetailsQuery,
  useGetAccountQuery,
  useUnlockAccountMutation,
  useUnlockUnregisteredAccountMutation,
} from "lib/api/react-query/account";

import MainLayout from "pages/MainLayout";

import classNames from "classnames";
import SearchInput from "components/SearchInput";
import TextInput from "components/TextInput";
import {parseISO} from "date-fns";
import {
  useIsAgentRoleACH,
  useIsAgentRoleUnlock,
  useIsAgentRoleUSA,
  useIsAgentRoleUsername,
} from "lib/auth";
import {usernameMaxLength, usernameMinLength} from "lib/constants/lengths";
import {customerUsernameInvalidMessage, isUsernameValid} from "lib/validation";
import {SendTestPushNotificationButton} from "pages/AccountOverviewPage";
import styles from "./AccountOverviewPage.module.css";

const strings = {
  accountNotFound: "No account matches the provided search. Please try again.",
  fetchAccountError:
    "Sorry, there was an error requesting the account information.",
  removeAccountLock: "Remove Account Lock",
  viewSecureMail: "View Secure Mail",
  changeUsername: "Change Username",
  unlockError: "Sorry, there was an issue unlocking the account.",
  userName: (userName: string) => `Username: ${userName}`,
  reviewEnrollment: "Review Existing Autopay Enrollment",
  enrollInAutopay: "Enroll in Autopay",
  newUsername: "New Username",
  submit: "Submit",
  cancel: "Cancel",
  changeUsernameError: "Sorry, there was an error changing the username.",
  registration: "Registered on",
  searchAccountPrompt: "Search Account ID",
  composeSecuremail: "Compose SecureMail",
  autopayNotAvailable: "Autopay not available for Card Member",
  notRegistered: "Account is Not Registered",
  ssnLocked: "SSN Locked",
  accountNumberLocked: "Account Number Locked",
  unregisteredUnlockError:
    "Sorry, there was an issue unlocking the unregistered account.",
};

type LeftSideBarProps = {
  query: string;
  onChangeQuery: (accountNum: string) => void;
  onSearch: () => void;
  loading?: boolean;
};
function LeftSideBar({
  query,
  onChangeQuery,
  onSearch,
  loading,
}: LeftSideBarProps) {
  return (
    <form className={styles.leftSideBar}>
      <span className={styles.selectTitle}>Account Search</span>
      <SearchInput
        className={styles.searchInput}
        value={query}
        onChange={onChangeQuery}
        prompt={strings.searchAccountPrompt}
      />
      <Button
        variant={"unfilled"}
        onClick={(e) => {
          e.preventDefault();
          onSearch();
        }}
        disabled={!query}
        loading={loading}
      >
        Search
      </Button>
    </form>
  );
}

function ChangeUsernameForm({
  partyId,
  dwbuid,
  onDismiss,
}: {
  partyId: number;
  dwbuid: string;
  onDismiss: () => void;
}) {
  const {mutateAsync: changeUsername, isLoading: isChangeUsernameLoading} =
    useChangeUsernameMutation();

  const [newUsername, setNewUsername] = useState("");
  const userNameInvalidMessage = customerUsernameInvalidMessage(newUsername);
  const userNameInvalid = !isUsernameValid(newUsername);
  const canSubmit = !(userNameInvalid || userNameInvalidMessage);

  const onClickSubmit = () => {
    if (!canSubmit) {
      return;
    }
    changeUsername({partyId, dwbuid, newUsername})
      .then(onDismiss)
      .catch(() => {
        alert(strings.changeUsernameError);
      });
  };

  return (
    <div className={styles.changeUsernameForm}>
      <div className={styles.changeUsernameTitle}>{strings.changeUsername}</div>
      <TextInput
        className={styles.newUsernameInput}
        placeholder={strings.newUsername}
        value={newUsername}
        onChangeText={setNewUsername}
        minLength={usernameMinLength}
        maxLength={usernameMaxLength}
        invalid={userNameInvalid}
        invalidMessage={userNameInvalidMessage}
      />
      <Button
        className={styles.submitNewUsernameButton}
        variant={"filled"}
        onClick={onClickSubmit}
        disabled={!canSubmit}
        loading={isChangeUsernameLoading}
      >
        {strings.submit}
      </Button>
      <Button
        className={styles.cancelUsernameChangeButton}
        variant={"unfilled"}
        onClick={onDismiss}
      >
        {strings.cancel}
      </Button>
    </div>
  );
}

export default function AccountOverviewPage() {
  const {dwbuid = ""} = useParams();
  const [dwbuidInput, setDWBUIDInput] = useState(dwbuid);
  const [showChangeUsernameForm, setShowChangeUsernameForm] = useState(false);
  const [displayText, setDisplayText] = useState("");

  const navigate = useNavigate();
  const isAgentRoleACH = useIsAgentRoleACH();
  const isAgentRoleUSA = useIsAgentRoleUSA();
  const isAgentRoleUsername = useIsAgentRoleUsername();
  const isAgentRoleUnlock = useIsAgentRoleUnlock();

  const {isFetching: isGetAccountFetching, error: getAccountError} =
    useGetAccountQuery({
      dwbuid,
    });
  useEffect(() => {
    if (getAccountError as FetchError) {
      const err: FetchError = getAccountError as FetchError;
      if (err.response?.status === 401) {
        return;
      }
      if (err.response?.status === 404 || err?.response?.status === 400) {
        setDisplayText(strings.accountNotFound);
      } else {
        alert(strings.fetchAccountError);
      }
    }
  }, [getAccountError]);

  const {data: account} = useGetAccountQuery({
    dwbuid,
  });
  const {
    data: accountDetails,
    isFetching: isGetAccountDetailsFetching,
    error: getAccountDetailsError,
  } = useGetAccountDetailsQuery({
    dwbuid,
  });
  useEffect(() => {
    if (getAccountDetailsError as FetchError) {
      const err: FetchError = getAccountDetailsError as FetchError;
      if (err.response?.status === 401) {
        return;
      }
      if (err.response?.status === 404 || err?.response?.status === 400) {
        setDisplayText(strings.accountNotFound);
      } else {
        alert(strings.fetchAccountError);
      }
    }
  }, [getAccountDetailsError]);

  const {mutateAsync: unlockAccount, isLoading: isUnlockAccountLoading} =
    useUnlockAccountMutation();

  const {
    mutateAsync: unlockUnregisteredAccount,
    isLoading: isUnlockUnregisteredAccountLoading,
  } = useUnlockUnregisteredAccountMutation();

  const disableAutopay = !!accountDetails?.autopayDisabledReason;

  const onClickUnlockAccount = () => {
    if (!account) {
      return;
    }
    unlockAccount({partyId: account.partyId, dwbuid}).catch(() => {
      alert(strings.unlockError);
    });
  };

  const onClickAutopay = () => {
    navigate(`/account/${dwbuid}/autopay`);
  };

  const onClickComposeSecureMail = () => {
    navigate(`/account/${dwbuid}/compose_secure-mail`);
  };

  const onClickViewSecureMail = () => {
    navigate(`/account/${dwbuid}/secure-mail`);
  };

  const onClickChangeUsername = () => {
    setShowChangeUsernameForm(true);
  };

  const updateAccountNumQuery = (q: string) => {
    const numericPattern = /\D/g;
    setDWBUIDInput(q.replace(numericPattern, ""));
  };

  const renderContent = () => {
    if (isGetAccountDetailsFetching) {
      return (
        <div className={styles.loading}>
          <ActivityIndicator />
        </div>
      );
    }
    if (!account || !accountDetails) {
      return <span className={styles.displayText}>{displayText}</span>;
    }
    const isRegistered = accountDetails.registrationDateTime !== null;

    const renderUnlockButton = () => {
      if (!accountDetails.isLocked || !isAgentRoleUnlock) {
        return null;
      }
      if (isUnlockAccountLoading) {
        return (
          <div className={styles.loading}>
            <ActivityIndicator />
          </div>
        );
      }
      return (
        <button
          className={styles.unlockButton}
          onClick={onClickUnlockAccount}
          disabled={isUnlockAccountLoading || !isRegistered}
        >
          <span className={styles.unlockText}>{strings.removeAccountLock}</span>
        </button>
      );
    };

    const renderChangeUsernameSection = () => {
      if (!account || !accountDetails || accountDetails.isLocked) {
        return null;
      }
      if (showChangeUsernameForm) {
        return (
          <ChangeUsernameForm
            dwbuid={dwbuid}
            partyId={account.partyId}
            onDismiss={() => {
              setShowChangeUsernameForm(false);
            }}
          />
        );
      }
      return (
        <Button
          className={styles.button}
          variant={"unfilled"}
          onClick={onClickChangeUsername}
          disabled={!isRegistered}
        >
          {strings.changeUsername}
        </Button>
      );
    };

    if (accountDetails.ssnLocked || accountDetails.accountNumberLocked) {
      return (
        <div className={styles.validContainer}>
          <img className={styles.lockIcon} alt="locked" src={lockedIcon} />
          <span className={styles.lockText}>Account {dwbuid} Unregistered</span>
          {accountDetails.ssnLocked ? (
            <div className={styles.lockText}>{strings.ssnLocked}</div>
          ) : null}
          {accountDetails.accountNumberLocked ? (
            <span className={styles.lockText}>
              {strings.accountNumberLocked}
            </span>
          ) : null}
          {isAgentRoleUnlock ? (
            <Button
              className={styles.button}
              variant={"filled"}
              onClick={() => {
                unlockUnregisteredAccount({
                  dwbuid: Number(dwbuid),
                }).catch(() => {
                  alert(strings.unregisteredUnlockError);
                });
              }}
              loading={isUnlockUnregisteredAccountLoading}
            >
              {strings.removeAccountLock}
            </Button>
          ) : null}
        </div>
      );
    }

    const renderAutopayEligibilityText = () => {
      if (disableAutopay) {
        return (
          <div className={styles.autopayDisabledReason}>
            {accountDetails.autopayDisabledReason}
          </div>
        );
      }
    };

    return (
      <div className={styles.validContainer}>
        <img
          className={styles.lockIcon}
          alt="locked"
          src={accountDetails.isLocked ? lockedIcon : unlockedIcon}
        />
        <div className={styles.lockText}>
          {strings.userName(accountDetails.userName)}
        </div>
        <span className={styles.lockText}>
          Account {dwbuid} {accountDetails.isLocked ? "Locked" : "Unlocked"}
        </span>
        {renderUnlockButton()}
        {accountDetails.registrationDateTime !== null ? (
          <div className={styles.dateRegistered}>
            {`${strings.registration} ${formatInCentralTime(
              parseISO(accountDetails.registrationDateTime),
              "MM/dd/yyyy hh:mm a",
            )}`}
          </div>
        ) : null}
        <>
          {isAgentRoleUSA ? (
            <Button
              className={classNames(styles.button, styles.viewSecureMailButton)}
              variant={"filled"}
              onClick={onClickViewSecureMail}
              disabled={!isRegistered || isUnlockAccountLoading}
            >
              {strings.viewSecureMail}
            </Button>
          ) : null}
          {isAgentRoleACH ? (
            <>
              <Button
                className={classNames({
                  [styles.button]: true,
                  [styles.enrollAutopayButtonDisabled]: disableAutopay,
                })}
                variant="filled"
                onClick={onClickAutopay}
                disabled={disableAutopay}
              >
                {accountDetails.isEnrolledAutopay
                  ? strings.reviewEnrollment
                  : strings.enrollInAutopay}
              </Button>
              {disableAutopay ? renderAutopayEligibilityText() : null}
            </>
          ) : null}
          {isAgentRoleUSA ? (
            <Button
              className={styles.button}
              variant="filled"
              onClick={onClickComposeSecureMail}
              disabled={!isRegistered}
            >
              {strings.composeSecuremail}
            </Button>
          ) : null}
          <SendTestPushNotificationButton
            partyId={account.partyId}
            disabled={!isRegistered}
          />
          {isAgentRoleUsername ? (
            <div className={styles.changeUsernameSection}>
              {renderChangeUsernameSection()}
            </div>
          ) : null}
        </>
      </div>
    );
  };

  return (
    <MainLayout
      onClickBack={() => navigate(-1)}
      renderLeftContent={() => (
        <LeftSideBar
          query={dwbuidInput}
          onChangeQuery={updateAccountNumQuery}
          onSearch={() => {
            navigate({
              pathname: `/account/${dwbuidInput}`,
            });
          }}
          loading={isGetAccountFetching}
        />
      )}
    >
      <div className={styles.contentContainer}>{renderContent()}</div>
    </MainLayout>
  );
}
