import styles from "./Footer.module.css";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className={styles.footer}>
      Copyright © 2001-{currentYear} 1st Financial Bank USA - Member FDIC
    </footer>
  );
}
