import instance from "lib/api/instance";

export type DiagnosticsResponse = Record<string, string>;

export function getDiagnostics({
  dwbuid,
}: {
  dwbuid: string;
}): Promise<DiagnosticsResponse> {
  return instance
    .fetch({path: `/staff/diagnostics/${dwbuid}`, method: "GET"})
    .then((res) => res.json());
}
