import backArrow from "assets/images/back-arrow.svg";
import forwardArrow from "assets/images/forward-arrow.svg";
import classNames from "classnames";

import ActivityIndicator from "components/ActivityIndicator";

import styles from "./MainLayout.module.css";

type MainLayoutProps = {
  children: React.ReactNode;
  renderLeftContent?: () => React.ReactNode;
  onClickBack?: () => void;
  forwardButton?: {
    label: string;
    loading: boolean;
    onClick: () => void;
  };
};

function ForwardBackBar(
  onClickBack?: () => void,
  forwardButton?: {
    label: string;
    loading: boolean;
    onClick: () => void;
  },
) {
  return (
    <div
      className={classNames(
        styles.forwardBackBar,
        !onClickBack ? styles.forwardOnly : undefined,
      )}
    >
      {onClickBack ? (
        <button className={styles.textButtons} onClick={onClickBack}>
          <img className={styles.backButtonImage} src={backArrow} alt="" />
          {"Back"}
        </button>
      ) : null}
      {forwardButton ? (
        <button className={styles.textButtons} onClick={forwardButton.onClick}>
          {!forwardButton.loading ? forwardButton.label : <ActivityIndicator />}
          <img
            className={styles.forwardButtonImage}
            src={forwardArrow}
            alt=""
          />
        </button>
      ) : null}
    </div>
  );
}

export default function MainLayout(props: MainLayoutProps) {
  return (
    <div className={styles.container}>
      {props.onClickBack || props.forwardButton
        ? ForwardBackBar(props.onClickBack, props.forwardButton)
        : null}
      <div className={styles.layout}>
        {props.renderLeftContent ? (
          <div className={styles.leftSide}>{props.renderLeftContent()}</div>
        ) : null}
        <div
          className={
            props.renderLeftContent ? styles.rightSide : styles.mainArea
          }
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}
