import classNames from "classnames";

import styles from "./PaymentAmountSelectionButton.module.css";

export type PaymentAmountSelectionButtonProps = {
  isSelected: boolean;
  onClick: () => void;
  title: string;
  children?: React.ReactNode;
  className?: string;
  invalid?: boolean;
  invalidMessage?: string;
};
export default function PaymentAmountSelectionButton({
  isSelected,
  onClick,
  title,
  children,
  className,
  invalid = false,
  invalidMessage,
}: PaymentAmountSelectionButtonProps) {
  const showInvalid = invalid || !!invalidMessage;
  return (
    <button
      className={classNames(styles.button, className, {
        [styles.selected]: isSelected && !invalid,
        [styles.invalid]: isSelected && showInvalid,
      })}
      onClick={onClick}
    >
      <div className={styles.title}>
        {invalidMessage && isSelected ? invalidMessage : title}
      </div>
      {children ? (
        <div
          className={classNames(styles.childrenTransition, {
            [styles.show]: isSelected,
            [styles.hide]: !isSelected,
          })}
        >
          <div
            className={classNames(styles.childrenContainer, {
              [styles.noDisplay]: !isSelected,
            })}
          >
            {children}
          </div>
        </div>
      ) : null}
    </button>
  );
}
