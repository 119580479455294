import closeIcon from "assets/images/close-button.svg";
import classNames from "classnames";
import ReactModal, {Props} from "react-modal";

import styles from "./StyledModal.module.css";

type StyledModalProps = {
  closeButtonClassName?: string;
  children: string | JSX.Element;
} & Props;

export default function StyledModal({
  className,
  overlayClassName,
  closeButtonClassName,
  onRequestClose,
  children,
  ...props
}: StyledModalProps) {
  const modalStyle = classNames(
    styles.modal,
    onRequestClose && styles.modalWithCloseButton,
    className,
  );
  const overlayStyle = classNames(styles.overlay, overlayClassName);
  const closeButtonStyle = classNames(styles.closeButton, closeButtonClassName);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ReactModal
      className={modalStyle}
      overlayClassName={overlayStyle}
      onRequestClose={onRequestClose}
      shouldCloseOnEsc
      preventScroll
      appElement={document.getElementById("root") || undefined}
      {...props}
    >
      {onRequestClose ? (
        <button className={closeButtonStyle} onClick={onRequestClose}>
          <img className={styles.closeIcon} alt="Close Modal" src={closeIcon} />
        </button>
      ) : null}
      {children}
    </ReactModal>
  );
}
