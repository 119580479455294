import classNames from "classnames";
import styles from "./RadioButton.module.css";

type Props = {
  label: string;
  isSelected: boolean;
  onSelect: () => void;
  className?: string;
};

export default function RadioButton({
  label,
  isSelected,
  onSelect,
  className,
}: Props) {
  return (
    <label className={classNames(styles.container, className)}>
      <input
        type="radio"
        onClick={onSelect}
        className={styles.container}
        checked={isSelected}
      />
      <div className={styles.labelText}>{label}</div>
    </label>
  );
}
