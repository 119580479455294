import instance from "lib/api/instance";

export type ISO8601String = string;

export type AccountRouteParams = {
  dwbuid: string;
};

export type AccountResponse = {
  partyId: number;
  firstName: string;
  lastName: string;
};

export function getAccount(req: AccountRouteParams): Promise<AccountResponse> {
  return instance
    .fetch({
      method: "GET",
      path: `/staff/account/${req.dwbuid}`,
    })
    .then((res) => res.json());
}

export type AccountDetailsRouteParams = {
  dwbuid: string;
};

export type AccountDetailsResponse = {
  isLocked: boolean;
  userName: string;
  registrationDateTime: ISO8601String | null;
  ssnLocked: boolean;
  accountNumberLocked: boolean;
  isEnrolledAutopay: boolean;
  autopayDisabledReason?: string | null;
};

export function getAccountDetails(
  req: AccountDetailsRouteParams,
): Promise<AccountDetailsResponse> {
  return instance
    .fetch({
      method: "GET",
      path: `/staff/account/${req.dwbuid}/details`,
    })
    .then((res) => res.json());
}

export type PatchAccountRequest = {
  partyId: number;
  dwbuid: string;
};

export function patchAccount({partyId}: PatchAccountRequest) {
  return instance.fetch({
    path: "/staff/account",
    method: "PATCH",
    body: JSON.stringify({partyId}),
  });
}

export type PostAccountChangeUsernameRequest = {
  partyId: number;
  dwbuid: string;
  newUsername: string;
};

export function postAccountChangeUsername({
  partyId,
  newUsername,
}: PostAccountChangeUsernameRequest) {
  return instance.fetch({
    path: `/staff/account/username`,
    method: "POST",
    body: JSON.stringify({partyId, newUsername}),
  });
}

export type PostSendTestPushNotificationRequest = {
  partyId: number;
};
export function postSendTestPushNotification({
  partyId,
}: PostSendTestPushNotificationRequest) {
  return instance.fetch({
    path: `/staff/account/${partyId}/push`,
    method: "POST",
  });
}

export type PatchRegistrationRequest = {
  dwbuid: number;
};

export function patchRegistration({dwbuid}: PatchRegistrationRequest) {
  return instance.fetch({
    path: "/staff/registration",
    method: "PATCH",
    body: JSON.stringify({dwbuid}),
  });
}
