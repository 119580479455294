import {
  accountNumberMaxLength,
  accountNumberMinLength,
  routingNumberLength,
  usernameMaxLength,
  usernameMinLength,
} from "lib/constants/lengths";

export const isNameValid = (name?: string): boolean => {
  if (!name) {
    return false;
  }
  // Only allow words, numbers, and apostrophe ('), accent (`), and hypen (-)
  const noWhiteSpaceName = name.replace(/\s/g, "");
  const validCharactersRegex = /^[a-zA-Z0-9'`-]+$/;
  return validCharactersRegex.test(noWhiteSpaceName);
};

export const isRoutingNumberValid = (
  routingNumber: string | undefined,
): boolean => {
  return (
    routingNumber !== undefined && routingNumber.length === routingNumberLength
  );
};

export const isAccountNumberValid = (
  accountNumber: string | undefined,
): boolean => {
  return (
    accountNumber !== undefined &&
    accountNumber.length >= accountNumberMinLength &&
    accountNumber.length <= accountNumberMaxLength
  );
};

export const isUsernameValid = (username: string | undefined): boolean => {
  return (
    username !== undefined &&
    !username.includes(" ") &&
    username.length >= usernameMinLength &&
    username.length <= usernameMaxLength
  );
};

const strings = {
  customerUsernames: {
    tooShort: `Username must be at least ${usernameMinLength} characters long`,
    noSpaces: "Username must not include spaces",
    hasNonAlphanumericCharacters:
      "Username must only include alphanumeric characters",
  },
  routingNumber: {
    tooShortOrLong: `ABA Number must be ${routingNumberLength} characters long`,
    noSpaces: "ABA Number must not include spaces",
  },
  accountNumber: {
    tooShort: `DDA Number must be at least ${accountNumberMinLength} characters long`,
    tooLong: `DDA Number must be ${accountNumberMaxLength} characters or less`,
    noSpaces: "DDA Number must not include spaces",
  },
  names: {
    noSpecialCharacters:
      "Names printed on your checks may include only letters, numbers, and the following characters: apostrophe ('), accent (`), and hyphen (-)",
  },
};

export const customerUsernameInvalidMessage = (
  customerUsername: string,
): string | undefined => {
  if (customerUsername.includes(" ")) {
    return strings.customerUsernames.noSpaces;
  }
  const nonAlphanumericRegex = /[^a-zA-Z0-9]/;
  const hasNonAlphanumericCharacters =
    nonAlphanumericRegex.test(customerUsername);
  if (hasNonAlphanumericCharacters) {
    return strings.customerUsernames.hasNonAlphanumericCharacters;
  }
  if (customerUsername.length < usernameMinLength) {
    return strings.customerUsernames.tooShort;
  }
  return undefined;
};

export const routingNumberInvalidMessage = (
  routingNumber: string,
): string | undefined => {
  if (routingNumber.includes(" ")) {
    return strings.routingNumber.noSpaces;
  }
  if (routingNumber.length !== routingNumberLength) {
    return strings.routingNumber.tooShortOrLong;
  }
  return undefined;
};

export const accountNumberInvalidMessage = (
  accountNumber: string,
): string | undefined => {
  if (accountNumber.includes(" ")) {
    return strings.accountNumber.noSpaces;
  }
  if (accountNumber.length < accountNumberMinLength) {
    return strings.accountNumber.tooShort;
  }
  if (accountNumber.length > accountNumberMaxLength) {
    return strings.accountNumber.tooLong;
  }
  return undefined;
};

export const paymentAccountNameInvalidMessage = (
  name: string,
): string | undefined => {
  // No need to show this on empty names, handled elsewhere
  if (!isNameValid(name) && name !== "") {
    return strings.names.noSpecialCharacters;
  }
  return undefined;
};
