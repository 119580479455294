import classnames from "classnames";
import {useState} from "react";

import styles from "./TextInput.module.css";

type Props = {
  className?: string;
  type?: string;
  placeholder: string;
  value: string;
  onChangeText: (value: string) => void;
  invalid?: boolean;
  invalidMessage?: string;
  maxLength?: number;
  minLength?: number;
};

export default function TextInput({
  className,
  type,
  placeholder,
  value,
  onChangeText,
  invalid,
  invalidMessage,
  maxLength,
  minLength,
}: Props) {
  const [isFocused, setIsFocused] = useState(false);
  const [didBlur, setDidBlur] = useState(false);
  const showInvalid =
    (didBlur || (isFocused && didBlur)) && (invalid || !!invalidMessage);

  return (
    <div className={classnames(styles.container, className)}>
      <input
        className={classnames(styles.input, styles.textStyle, {
          [styles.inputInvalid]: showInvalid,
        })}
        type={type ?? "text"}
        placeholder={placeholder}
        value={value}
        onChange={(event) => {
          onChangeText(event.target.value);
        }}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setDidBlur(true);
          setIsFocused(false);
        }}
        maxLength={maxLength}
        minLength={minLength}
      />
      {showInvalid && invalidMessage ? (
        <div className={styles.inputInvalidMessage}>{invalidMessage}</div>
      ) : null}
    </div>
  );
}
