import dropDownIcon from "assets/images/drop-down.svg";
import classNames from "classnames";

import styles from "./Select.module.css";

type Props<T extends string> = {
  className?: string;
  options: Array<{
    label: string;
    value: T;
  }>;
  selectedValue: T;
  placeholder?: string;
  onChange: (value: T) => void;
  disabled?: boolean;
};
export default function Select<T extends string>(props: Props<T>) {
  const selectedOptionIndex = props.options.findIndex(
    (option) => option.value === props.selectedValue,
  );
  return (
    <div
      className={classNames(
        {
          [styles.selectContainer]: true,
          [styles.disabledContainer]: props.disabled,
        },
        props.className,
      )}
    >
      <select
        className={classNames(styles.select)}
        value={props.selectedValue}
        onChange={(e) => props.onChange(e.target.value as T)}
        disabled={props.disabled}
      >
        {props.placeholder ? (
          <option value="" disabled>
            {props.placeholder}
          </option>
        ) : null}
        {props.options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            className={styles.options}
          >
            {option.label}
          </option>
        ))}
      </select>
      <div className={styles.content}>
        <div className={styles.selectedLabel}>
          {props.options[selectedOptionIndex].label}
        </div>
        <img src={dropDownIcon} alt="" />
      </div>
    </div>
  );
}
