import classNames from "classnames";
import {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import ActivityIndicator from "components/ActivityIndicator";
import DateInput, {DateString} from "components/DateInput";
import SecureMailTable from "components/SecureMailTable";

import {useCardmemberMailList} from "lib/api/react-query/secureMail";

import MainLayout from "pages/MainLayout";

import {useGetAccountQuery} from "lib/api/react-query/account";
import styles from "./AccountSecureMailQueuePage.module.css";

const strings = {
  noMessages: "No secure messsages found.",
  error: "Sorry, there was an error retrieving the secure messages.",
};

const PAGE_SIZE = 5;

export default function AccountSecureMailQueuePage() {
  const [selectedPage, setSelectedPage] = useState(0);
  const [startDate, setStartDate] = useState<DateString>("");
  const [endDate, setEndDate] = useState<DateString>("");
  const navigate = useNavigate();
  const {dwbuid = ""} = useParams();
  const {data: account, isFetching: isGetAccountFetching} = useGetAccountQuery({
    dwbuid,
  });
  const partyId = Number(account ? account?.partyId : "");
  const {
    data: secureMail,
    isLoading: isLoadingSecureMail,
    error,
  } = useCardmemberMailList(
    partyId,
    {
      page: selectedPage,
      size: PAGE_SIZE,
      start: startDate !== "" ? new Date(startDate) : undefined,
      end: endDate !== "" ? new Date(endDate) : undefined,
    },
    {enabled: !!account?.partyId},
  );
  const customerFullName = isGetAccountFetching
    ? "Loading..."
    : account
    ? `${account.firstName} ${account.lastName}`
    : "N/A";

  return (
    <MainLayout onClickBack={() => navigate(`/account/${dwbuid}`)}>
      <header className={styles.accountHeader}>
        <div className={styles.userInfo}>
          <span
            className={styles.accountIdText}
          >{`Account ID: ${dwbuid}`}</span>
          <span
            className={styles.accountIdText}
          >{`CM Name: ${customerFullName}`}</span>
        </div>
        <div className={styles.dateInputs}>
          <DateInput
            className={styles.dateInput}
            label="Start Date: "
            value={startDate}
            onChangeDate={setStartDate}
            disabled={isLoadingSecureMail || isGetAccountFetching}
          />
          <DateInput
            className={classNames(styles.dateInputMargin, styles.dateInput)}
            label="End Date: "
            value={endDate}
            onChangeDate={setEndDate}
            disabled={isLoadingSecureMail || isGetAccountFetching}
          />
        </div>
      </header>
      {secureMail && secureMail.total !== 0 ? (
        <SecureMailTable
          variant={"cardmember"}
          secureMail={{
            ...secureMail,
            secureMail: secureMail.secureMail.map((mail) => {
              return {...mail, dwbuid: -1};
            }),
          }}
          selectedPageNumber={selectedPage}
          pageSize={PAGE_SIZE}
          onChangePage={setSelectedPage}
          onClickRow={(id) =>
            navigate({
              pathname: `/secure-mail/${id}`,
              search: `account=${dwbuid}`,
            })
          }
        />
      ) : isLoadingSecureMail || isGetAccountFetching ? (
        <div className={styles.container}>
          <ActivityIndicator />
        </div>
      ) : (
        <div className={styles.container}>
          {error ? strings.error : strings.noMessages}
        </div>
      )}
    </MainLayout>
  );
}
