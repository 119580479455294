import ActivityIndicator from "components/ActivityIndicator";
import Button from "components/Button";
import ConfirmationModal from "components/ConfirmationModal";
import EnrollmentIndicator from "components/EnrollmentIndicator";
import PaymentAmountSelector from "components/PaymentAmountSelector";
import RadioButton from "components/RadioButton";
import TextInput from "components/TextInput";
import {format, parseISO} from "date-fns";
import {
  CancelAutopayEnrollmentRequestBody,
  EnrollAutopayRequestBody,
  SkipAutopayPaymentRequestBody,
} from "lib/api/autopay";
import {FetchError} from "lib/api/instance";
import {useGetAccountQuery} from "lib/api/react-query/account";
import {
  useEnrollAutopayMutation,
  useGetAutopayEnrollmentOverview,
  useRemoveAutopayEnrollment,
  useSkipAutopayPayment,
} from "lib/api/react-query/autopay";
import {
  accountNumberMaxLength,
  accountNumberMinLength,
  routingNumberLength,
} from "lib/constants/lengths";
import {formatInCentralTime} from "lib/formaInCentralTime";
import parseFetchError from "lib/parseFetchError";
import {
  accountNumberInvalidMessage,
  isAccountNumberValid,
  isNameValid,
  isRoutingNumberValid,
  paymentAccountNameInvalidMessage,
  routingNumberInvalidMessage,
} from "lib/validation";
import {useAutopayEnrollmentForm} from "pages/AutopayPage/AutopayPage.hooks";
import MainLayout from "pages/MainLayout";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import styles from "./AutopayPage.module.css";

const strings = {
  dwbuid: (dwbuid: string) => `Account ${dwbuid}`,
  accountSearch: "Account Search",
  searchAccountPrompt: "Search Account ID",
  search: "Search",
  enrollmentDate: "Enrolled Date",
  removeAutopay: "Remove Autopay Enrollment",
  skipAutopay: "Skip Autopay Payment",
  cmOrThirdPartyPrompt:
    "Please select whether this request was made by the Card Member or Third Party:",
  skipAutopayConfirmation:
    "Are you sure that you want to skip this Autopay Payment?",
  removeAutopayConfirmation: (date: string) =>
    `If you continue, you will be able to re-enroll the Card Member after ${date}`,
  cancel: "Cancel",
  holder: "Holder",
  cardMember: "Card Member",
  thirdParty: "Third Party",
  firstName: "First Name",
  lastName: "Last Name",
  accountInformation: "Account Information",
  abaNumber: "ABA Number",
  ddaNumber: "DDA Number",
  amount: "Amount",
  enrollAutopay: "Enroll in Autopay",
  lastEnrollmentDate: "Last enrollment Date",
  unknown: "Unknown",
  skippedAutopayPayment: "This Month's Payment Skipped",
  initialDisplayText: "Please enter an account number.",
  accountNotFound: "No account matches the provided search. Please try again.",
  fetchAccountError:
    "Sorry, there was an error requesting the account information.",
  enrollAutopayError: "Sorry, there was an error enrolling in autopay.",
  removeAutopayEnrollmentError:
    "Sorry, there was an error removing autopay enrollment.",
  skipAutopayPaymentError:
    "Sorry, there was an error skipping autopay payment.",
};

export default function AutopayPage() {
  const navigate = useNavigate();
  const {dwbuid = ""} = useParams();
  const {data: account, isFetching: isGetAccountFetching} = useGetAccountQuery({
    dwbuid,
  });
  const partyId = account ? String(account?.partyId) : "";
  const {
    data: getAutopayEnrollmentOverviewResponse,
    isFetching: isAutopayEnrollmentOverviewFetching,
    error: getAutopayEnrollmentOverviewError,
  } = useGetAutopayEnrollmentOverview({partyId, dwbuid});
  const {mutateAsync: enrollAutopay, isLoading: isEnrollAutopayLoading} =
    useEnrollAutopayMutation();
  const {
    mutateAsync: removeAutopayEnrollment,
    isLoading: isRemoveAutopayEnrollmentLoading,
  } = useRemoveAutopayEnrollment();
  const {
    mutateAsync: skipAutopayPayment,
    isLoading: isSkipAutopayPaymentLoading,
  } = useSkipAutopayPayment();
  const [
    {
      holderType,
      firstName,
      lastName,
      abaNumber,
      ddaNumber,
      paymentAmountOption,
      fixedAmountInput,
      fixedAmountCents,
      fixedAmountErrorMessage,
      isComplete,
    },
    {generalDispatch, fixedAmountInputDispatch, validateFixedAmount},
  ] = useAutopayEnrollmentForm();
  const [isRemoveAutopayConfirmationOpen, setIsRemoveAutopayConfirmationOpen] =
    useState(false);
  const [isSkipAutopayConfirmationOpen, setIsSkipAutopayConfirmationOpen] =
    useState(false);
  const [cancelAutopayParty, setCancelAutopayParty] = useState<
    CancelAutopayEnrollmentRequestBody["party"] | null
  >(null);
  const [skipAutopayParty, setSkipAutopayParty] = useState<
    SkipAutopayPaymentRequestBody["party"] | null
  >(null);
  const [displayText, setDisplayText] = useState(strings.initialDisplayText);
  useEffect(() => {
    if (getAutopayEnrollmentOverviewError as FetchError) {
      const err: FetchError = getAutopayEnrollmentOverviewError as FetchError;
      if (err.response?.status === 401) {
        return;
      }
      if (err.response?.status === 404 || err?.response?.status === 400) {
        setDisplayText(strings.accountNotFound);
      } else {
        alert(strings.fetchAccountError);
      }
    }
  }, [getAutopayEnrollmentOverviewError]);
  const firstNameInvalidMessage = paymentAccountNameInvalidMessage(firstName);
  const lastNameInvalidMessage = paymentAccountNameInvalidMessage(lastName);
  const abaNumberInvalidMessage = routingNumberInvalidMessage(abaNumber);
  const ddaNumberInvalidMessage = accountNumberInvalidMessage(ddaNumber);

  const onClickRemoveAutopay = () => {
    setIsRemoveAutopayConfirmationOpen(true);
  };
  const onConfirmRemoveAutopay = () => {
    if (!cancelAutopayParty) {
      return;
    }
    removeAutopayEnrollment({
      partyId,
      party: cancelAutopayParty,
      dwbuid: Number(dwbuid),
    })
      .then(() => navigate(`/account/${dwbuid}`))
      .catch((err: FetchError) => {
        if (err.responseJson) {
          alert(parseFetchError(err));
          return;
        }
        alert(strings.removeAutopayEnrollmentError);
      });
  };
  const onClickEnrollAutopay = () => {
    if (!isComplete || !paymentAmountOption) {
      return;
    }
    // Construct body of request
    const body: EnrollAutopayRequestBody = {
      dwbuid: Number(dwbuid),
      abaNumber,
      ddaNumber,
      amount:
        paymentAmountOption === "FIXED"
          ? {type: paymentAmountOption, amountCents: fixedAmountCents}
          : {type: paymentAmountOption},
    };
    if (holderType === "Third Party") {
      body.thirdParty = {firstName, lastName};
    }
    enrollAutopay({partyId, ...body}).catch((err: FetchError) => {
      if (err.responseJson) {
        alert(parseFetchError(err));
        return;
      }
      alert(strings.enrollAutopayError);
    });
  };
  const onClickSkipAutopay = () => {
    setIsSkipAutopayConfirmationOpen(true);
  };
  const onConfirmSkipAutopay = () => {
    if (!skipAutopayParty) {
      return;
    }
    skipAutopayPayment({
      partyId,
      party: skipAutopayParty,
      dwbuid: Number(dwbuid),
    })
      .then(() => {
        setIsSkipAutopayConfirmationOpen(false);
      })
      .catch((err: FetchError) => {
        if (err.responseJson) {
          alert(parseFetchError(err));
          return;
        }
        alert(strings.skipAutopayPaymentError);
      });
  };

  const renderContent = () => {
    if (isAutopayEnrollmentOverviewFetching || isGetAccountFetching) {
      return (
        <div className={styles.loadingContainer}>
          <ActivityIndicator />
        </div>
      );
    }
    if (!getAutopayEnrollmentOverviewResponse) {
      return <span className={styles.displayText}>{displayText}</span>;
    }
    if (getAutopayEnrollmentOverviewResponse.enrollmentInfo) {
      return (
        <div className={styles.overviewContentContainer}>
          <div className={styles.overviewContent}>
            <div className={styles.leftHalf}>
              <div className={styles.dwbuid}>{strings.dwbuid(dwbuid)}</div>
              {Object.entries(
                getAutopayEnrollmentOverviewResponse.enrollmentInfo,
              ).map(([key, value]) => (
                <div key={`${key}:${value}}`} className={styles.overview}>
                  <div className={styles.overviewKey}>{key}</div>
                  <div className={styles.overviewValue}>{value}</div>
                </div>
              ))}
            </div>
            <div className={styles.rightHalf}>
              <EnrollmentIndicator
                className={styles.enrollmentIndicator}
                isEnrolled={true}
              />
              <div className={styles.enrollmentDate}>
                <div className={styles.enrollmentDateKey}>
                  {strings.enrollmentDate}
                </div>
                <div className={styles.enrollmentDateValue}>
                  {getAutopayEnrollmentOverviewResponse.enrolledDate
                    ? format(
                        parseISO(
                          getAutopayEnrollmentOverviewResponse.enrolledDate,
                        ),
                        "MM/dd/yyyy",
                      )
                    : strings.unknown}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.overviewButtonContainer}>
            <Button
              className={styles.removeAutopayButton}
              variant={"filled"}
              onClick={onClickRemoveAutopay}
              disabled={
                !!getAutopayEnrollmentOverviewResponse.cancelSkipAutopayDisabledReason
              }
            >
              {strings.removeAutopay}
            </Button>
            <Button
              className={styles.skipAutopayButton}
              variant={"filled"}
              onClick={onClickSkipAutopay}
              disabled={
                getAutopayEnrollmentOverviewResponse.isAutopaySkipped ||
                !!getAutopayEnrollmentOverviewResponse.cancelSkipAutopayDisabledReason
              }
            >
              {getAutopayEnrollmentOverviewResponse.isAutopaySkipped
                ? strings.skippedAutopayPayment
                : strings.skipAutopay}
            </Button>
          </div>
          {getAutopayEnrollmentOverviewResponse.cancelSkipAutopayDisabledReason ? (
            <div className={styles.autopayDisabledReason}>
              {
                getAutopayEnrollmentOverviewResponse.cancelSkipAutopayDisabledReason
              }
            </div>
          ) : null}
          <ConfirmationModal
            isOpen={isRemoveAutopayConfirmationOpen}
            title={strings.removeAutopay}
            onConfirm={onConfirmRemoveAutopay}
            onCancel={() => {
              setIsRemoveAutopayConfirmationOpen(false);
              setCancelAutopayParty(null);
            }}
            canConfirm={cancelAutopayParty !== null}
            labelConfirm={strings.removeAutopay}
            labelCancel={strings.cancel}
            isLoadingConfirmation={isRemoveAutopayEnrollmentLoading}
          >
            <div className={styles.confirmationModalText}>
              {strings.cmOrThirdPartyPrompt}
            </div>
            <div className={styles.radioButtonGroup}>
              <RadioButton
                className={styles.radioButton}
                label={"CM"}
                isSelected={cancelAutopayParty === "CM"}
                onSelect={() => setCancelAutopayParty("CM")}
              />
              <RadioButton
                label={"Third Party"}
                isSelected={cancelAutopayParty === "3RD-PARTY"}
                onSelect={() => setCancelAutopayParty("3RD-PARTY")}
              />
            </div>
            <div className={styles.confirmationModalText}>
              {strings.removeAutopayConfirmation(
                format(
                  parseISO(getAutopayEnrollmentOverviewResponse.reEnrollDate),
                  "MM/dd/yyyy",
                ),
              )}
            </div>
          </ConfirmationModal>
          <ConfirmationModal
            isOpen={isSkipAutopayConfirmationOpen}
            title={strings.skipAutopay}
            onConfirm={onConfirmSkipAutopay}
            onCancel={() => {
              setIsSkipAutopayConfirmationOpen(false);
              setSkipAutopayParty(null);
            }}
            canConfirm={skipAutopayParty !== null}
            labelConfirm={strings.skipAutopay}
            labelCancel={strings.cancel}
            isLoadingConfirmation={isSkipAutopayPaymentLoading}
          >
            <div className={styles.confirmationModalText}>
              {strings.cmOrThirdPartyPrompt}
            </div>
            <div className={styles.radioButtonGroup}>
              <RadioButton
                className={styles.radioButton}
                label={"CM"}
                isSelected={skipAutopayParty === "CM"}
                onSelect={() => setSkipAutopayParty("CM")}
              />
              <RadioButton
                label={"Third Party"}
                isSelected={skipAutopayParty === "3RD-PARTY"}
                onSelect={() => setSkipAutopayParty("3RD-PARTY")}
              />
            </div>
            <div className={styles.confirmationModalText}>
              {strings.skipAutopayConfirmation}
            </div>
          </ConfirmationModal>
        </div>
      );
    }
    return (
      <div className={styles.contentContainer}>
        <div className={styles.leftHalf}>
          <div className={styles.dwbuid}>{strings.dwbuid(dwbuid)}</div>
          <div className={styles.holderContainer}>
            <div className={styles.holderText}>{strings.holder}</div>
            <RadioButton
              className={styles.radioButton}
              label={strings.cardMember}
              isSelected={holderType === "Card Member"}
              onSelect={() => generalDispatch({holderType: "Card Member"})}
            />
            <RadioButton
              className={styles.radioButton}
              label={strings.thirdParty}
              isSelected={holderType === "Third Party"}
              onSelect={() => generalDispatch({holderType: "Third Party"})}
            />
          </div>
          {holderType === "Third Party" ? (
            <>
              <TextInput
                className={styles.textInput}
                placeholder={strings.firstName}
                value={firstName}
                onChangeText={(val) => generalDispatch({firstName: val})}
                invalid={!isNameValid(firstName)}
                invalidMessage={firstNameInvalidMessage}
              />
              <TextInput
                className={styles.textInput}
                placeholder={strings.lastName}
                value={lastName}
                onChangeText={(val) => generalDispatch({lastName: val})}
                invalid={!isNameValid(lastName)}
                invalidMessage={lastNameInvalidMessage}
              />
            </>
          ) : null}
          <div className={styles.accountInformationText}>
            {strings.accountInformation}
          </div>
          <TextInput
            className={styles.textInput}
            placeholder={strings.abaNumber}
            value={abaNumber}
            onChangeText={(val) => {
              const numericOnly = val.replace(/\D/g, "");
              generalDispatch({abaNumber: numericOnly});
            }}
            minLength={routingNumberLength}
            maxLength={routingNumberLength}
            invalid={!isRoutingNumberValid(abaNumber)}
            invalidMessage={abaNumberInvalidMessage}
          />
          <TextInput
            className={styles.textInput}
            placeholder={strings.ddaNumber}
            value={ddaNumber}
            onChangeText={(val) => {
              const numericOnly = val.replace(/\D/g, "");
              generalDispatch({ddaNumber: numericOnly});
            }}
            minLength={accountNumberMinLength}
            maxLength={accountNumberMaxLength}
            invalid={!isAccountNumberValid(ddaNumber)}
            invalidMessage={ddaNumberInvalidMessage}
          />
          <div className={styles.amountText}>{strings.amount}</div>
          <PaymentAmountSelector
            className={styles.paymentAmountSelector}
            selectedPaymentAmountOption={paymentAmountOption}
            onPressPaymentAmountOption={(val) =>
              generalDispatch({paymentAmountOption: val})
            }
            fixedAmount={fixedAmountInput}
            setFixedAmount={(val) => fixedAmountInputDispatch(val)}
            validateFixedAmount={validateFixedAmount}
            fixedAmountErrorMessage={fixedAmountErrorMessage}
          />
          <Button
            className={styles.enrollAutopayButton}
            variant={"filled"}
            onClick={onClickEnrollAutopay}
            disabled={!isComplete}
            loading={isEnrollAutopayLoading}
          >
            {strings.enrollAutopay}
          </Button>
        </div>
        <div className={styles.rightHalf}>
          <EnrollmentIndicator
            className={styles.enrollmentIndicator}
            isEnrolled={false}
          />
          {getAutopayEnrollmentOverviewResponse.lastEnrollmentDate ? (
            <div className={styles.enrollmentDate}>
              <div className={styles.enrollmentDateKey}>
                {strings.lastEnrollmentDate}
              </div>
              <div className={styles.enrollmentDateValue}>
                {formatInCentralTime(
                  parseISO(
                    getAutopayEnrollmentOverviewResponse.lastEnrollmentDate,
                  ),
                  "MM/dd/yyyy",
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <MainLayout onClickBack={() => navigate(`/account/${dwbuid}`)}>
      {renderContent()}
    </MainLayout>
  );
}
