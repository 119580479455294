import {PaymentAmountOption} from "components/PaymentAmountSelector";
import {
  MAX_AUTOPAY_AMOUNT_CENTS,
  MIN_AUTOPAY_AMOUNT_CENTS,
} from "lib/constants/paymentAmounts";
import {centsToDollarString, dollarStringToCents} from "lib/formatting";
import {
  isAccountNumberValid,
  isNameValid,
  isRoutingNumberValid,
} from "lib/validation";
import {useState} from "react";

const strings = {
  tooHigh: (maxValue: string) => `Please enter a value below ${maxValue}`,
  tooLow: (minValue: string) => `Please enter a value of ${minValue} or above`,
  invalid: "Invalid Input",
};

export type Holder = "Third Party" | "Card Member";

type FormInput = {
  holderType: Holder;
  firstName: string;
  lastName: string;
  abaNumber: string;
  ddaNumber: string;
  paymentAmountOption: PaymentAmountOption | null;
  fixedAmountInput: string;
};

type FormState = FormInput & {
  fixedAmountCents: number;
  fixedAmountErrorMessage: string | undefined;
  isComplete: boolean;
};

const isFormComplete = (formState: FormState) => {
  if (formState.paymentAmountOption === null) {
    return false;
  }
  if (formState.paymentAmountOption === "FIXED") {
    if (
      formState.fixedAmountErrorMessage ||
      formState.fixedAmountCents < MIN_AUTOPAY_AMOUNT_CENTS ||
      formState.fixedAmountCents >= MAX_AUTOPAY_AMOUNT_CENTS
    ) {
      return false;
    }
  }
  if (formState.holderType === "Third Party") {
    if (!isNameValid(formState.firstName) || !isNameValid(formState.lastName)) {
      return false;
    }
  }
  if (
    !isRoutingNumberValid(formState.abaNumber) ||
    !isAccountNumberValid(formState.ddaNumber)
  ) {
    return false;
  }
  return true;
};

const fixedAmountValidation = (val: string): string | undefined => {
  try {
    const newAmountCents = dollarStringToCents(val);
    if (newAmountCents < MIN_AUTOPAY_AMOUNT_CENTS) {
      return strings.tooLow(centsToDollarString(MIN_AUTOPAY_AMOUNT_CENTS));
    }
    if (newAmountCents >= MAX_AUTOPAY_AMOUNT_CENTS) {
      return strings.tooHigh(centsToDollarString(MAX_AUTOPAY_AMOUNT_CENTS));
    }
    return undefined;
  } catch (e) {
    return strings.invalid;
  }
};

export const useAutopayEnrollmentForm = (): [
  FormState,
  {
    generalDispatch: (payload: Partial<FormInput>) => void;
    fixedAmountInputDispatch: (amountInput: string) => void;
    validateFixedAmount: () => void;
  },
] => {
  const [formState, formDispatch] = useState<FormState>({
    holderType: "Card Member",
    firstName: "",
    lastName: "",
    abaNumber: "",
    ddaNumber: "",
    paymentAmountOption: null,
    fixedAmountInput: "",
    fixedAmountCents: 0,
    fixedAmountErrorMessage: undefined,
    isComplete: false,
  });

  const generalDispatch = (payload: Partial<FormState>) => {
    formDispatch((prevFormState) => {
      const newFormState = {
        ...prevFormState,
        ...payload,
      };
      return {...newFormState, isComplete: isFormComplete(newFormState)};
    });
  };

  const fixedAmountInputDispatch = (amountInput: string) => {
    formDispatch((prevFormState) => {
      let newAmountCents = 0;
      try {
        newAmountCents = dollarStringToCents(amountInput);
      } catch (e) {
        newAmountCents = -1;
      }
      const newFormState: FormState = {
        ...prevFormState,
        fixedAmountInput: amountInput,
        fixedAmountCents: newAmountCents,
      };
      return {
        ...newFormState,
        isComplete: isFormComplete(newFormState),
      };
    });
  };

  const validateFixedAmount = (): void => {
    const validationMessage = fixedAmountValidation(formState.fixedAmountInput);
    if (!validationMessage) {
      const newCentsAmount = dollarStringToCents(formState.fixedAmountInput);
      generalDispatch({
        fixedAmountInput: centsToDollarString(newCentsAmount),
        fixedAmountCents: newCentsAmount,
        fixedAmountErrorMessage: undefined,
      });
      return;
    }
    generalDispatch({
      fixedAmountCents: 0,
      fixedAmountErrorMessage: validationMessage,
    });
  };

  return [
    formState,
    {generalDispatch, fixedAmountInputDispatch, validateFixedAmount},
  ];
};
