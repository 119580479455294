import logo from "assets/images/1FB-logo.svg";
import classNames from "classnames";
import {Link, useLocation, useNavigate} from "react-router-dom";

import {useLogoutMutation} from "lib/api/react-query/auth";

import {FetchError} from "lib/api/instance";
import {useIsAgentRoleUSA} from "lib/auth";
import styles from "./NavigationHeader.module.css";

const strings = {
  logoutError: "Sorry, there was an error logging you out.",
};

export default function NavigationHeader() {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const search = useLocation().search;
  const isAccountSearch =
    pathname.startsWith("/account") || search.includes("account");
  const isSecureMailQueue =
    pathname.startsWith("/secure-mail") && !search.includes("account");

  const {mutateAsync: logout} = useLogoutMutation();
  const isAgentRoleUSA = useIsAgentRoleUSA();

  if (pathname.startsWith("/login")) {
    return (
      <header className={classNames(styles.header, styles.headerItems)}>
        <img src={logo} alt="" />
      </header>
    );
  }

  return (
    <header className={styles.header}>
      <div className={styles.headerItems}>
        <Link className={styles.logo} to="/">
          <img src={logo} alt="" />
        </Link>
        <Link
          className={classNames(
            styles.headerButton,
            isAccountSearch ? styles.headerButtonSelected : undefined,
          )}
          to="/account"
        >
          Account Search
        </Link>
        {isAgentRoleUSA ? (
          <Link
            className={classNames(
              styles.headerButton,
              isSecureMailQueue ? styles.headerButtonSelected : undefined,
            )}
            to="/secure-mail"
          >
            Secure Mail Queue
          </Link>
        ) : null}
      </div>
      <button
        className={styles.logout}
        onClick={() =>
          logout(null)
            .then(() => {
              //TODO: Add token handling
              navigate("/login");
            })
            .catch((error) => {
              if (error as FetchError) {
                const fetchError: FetchError = error as FetchError;
                if (fetchError.response?.status === 401) {
                  return;
                }
              }
              alert(strings.logoutError);
            })
        }
      >
        Log Out
      </button>
    </header>
  );
}
