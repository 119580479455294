import Button from "components/Button";
import StyledModal from "components/StyledModal";

import styles from "./AlertModal.module.css";

type Props = {
  isOpen: boolean;
  title: string;
  message: string;
  buttonText?: string;
  onClickConfirm: () => void;
};

export default function AlertModal({
  isOpen,
  title,
  message,
  buttonText,
  onClickConfirm,
}: Props) {
  return (
    <StyledModal isOpen={isOpen}>
      <div className={styles.container}>
        <h2 className={styles.title}>{title}</h2>
        <span className={styles.message}>{message}</span>
        <Button variant="filled" onClick={onClickConfirm}>
          {buttonText ?? "Confirm"}
        </Button>
      </div>
    </StyledModal>
  );
}
