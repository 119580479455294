import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import ActivityIndicator from "components/ActivityIndicator";
import ErrorBoundary from "components/ErrorBoundary";
import Footer from "components/Footer";
import NavigationHeader from "components/NavigationHeader";
import PageTitle from "components/PageTitle";

import {useAuthenticationState} from "lib/auth";

import AccountOverviewPage from "pages/AccountOverviewPage";
import AccountPage from "pages/AccountPage";
import AccountSecureMailQueuePage from "pages/AccountSecureMailQueuePage";
import AutopayPage from "pages/AutopayPage";
import ComposeSecureMailPage from "pages/ComposeSecureMailPage";
import DemoPage from "pages/DemoPage";
import DiagnosticsPage from "pages/DiagnosticsPage";
import LoginPage from "pages/LoginPage";
import SecureMailMessagingPage from "pages/SecureMailMessagingPage";
import SecureMailQueuePage from "pages/SecureMailQueuePage";

import styles from "App.module.css";
import SessionExpiredModal from "components/SessionExpiredModal";
import {useEffect, useState} from "react";

function App() {
  const location = useLocation();
  const relativePath = location.pathname + (location.search ?? "");

  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect");
  const [showExpiredSessionModal, setShowExpiredSessionModal] = useState(false);

  const [authenticationState, loadingAuthenticationState] =
    useAuthenticationState();

  useEffect(() => {
    if (!authenticationState.user) {
      if (authenticationState.signOutMethod === "SESSION_EXPIRED") {
        setShowExpiredSessionModal(true);
      }
    }
  }, [authenticationState]);

  if (loadingAuthenticationState) {
    return (
      <div className={styles.loadingContainer}>
        <ActivityIndicator />
      </div>
    );
  }

  return (
    <div className={styles.app}>
      <NavigationHeader />
      <SessionExpiredModal
        isOpen={showExpiredSessionModal}
        onRequestClose={() => setShowExpiredSessionModal(false)}
      />
      <ErrorBoundary>
        <Routes>
          <Route
            path="demo"
            element={
              <PageTitle title="Demo">
                <DemoPage />
              </PageTitle>
            }
          />
          {authenticationState.user ? (
            <>
              <Route path="*" element={<Navigate to="/" replace />} />
              <Route path="/" element={<Navigate to="account" replace />} />
              <Route
                path="login"
                element={
                  <Navigate
                    to={
                      redirectUrl && redirectUrl.startsWith("/")
                        ? redirectUrl
                        : "/"
                    }
                    replace
                  />
                }
              />
              <Route path="account">
                <Route
                  path=""
                  element={
                    <PageTitle title="Account Search">
                      <AccountPage />
                    </PageTitle>
                  }
                />
                <Route
                  path=":dwbuid"
                  element={
                    <PageTitle title="Account Overview - :dwbuid">
                      <AccountOverviewPage />
                    </PageTitle>
                  }
                />
                <Route
                  path=":dwbuid/secure-mail"
                  element={
                    <PageTitle title="Account Search - :dwbuid">
                      <AccountSecureMailQueuePage />
                    </PageTitle>
                  }
                />
                <Route
                  path=":dwbuid/autopay"
                  element={
                    <PageTitle title="Autopay - :dwbuid">
                      <AutopayPage />
                    </PageTitle>
                  }
                />
                <Route
                  path=":dwbuid/compose_secure-mail"
                  element={
                    <PageTitle title="Compose SecureMail - :dwbuid">
                      <ComposeSecureMailPage />
                    </PageTitle>
                  }
                />
              </Route>
              <Route path="secure-mail">
                <Route
                  path=""
                  element={
                    <PageTitle title="Secure Mail Queue">
                      <SecureMailQueuePage />
                    </PageTitle>
                  }
                />
                <Route
                  path=":id"
                  element={
                    <PageTitle title="Secure Mail Chat">
                      <SecureMailMessagingPage />
                    </PageTitle>
                  }
                />
              </Route>
              <Route path="diagnostics">
                <Route
                  path=""
                  element={
                    <PageTitle title="Diagnostics">
                      <DiagnosticsPage />
                    </PageTitle>
                  }
                />
              </Route>
            </>
          ) : (
            <>
              <Route
                path="*"
                element={
                  <Navigate
                    to={{
                      pathname: "/login",
                      search:
                        relativePath !== "" && relativePath !== "/"
                          ? `redirect=${encodeURIComponent(relativePath)}`
                          : undefined,
                    }}
                  />
                }
              />
              <Route
                path="login"
                element={
                  <PageTitle title="Login">
                    <LoginPage />
                  </PageTitle>
                }
              />
            </>
          )}
        </Routes>
      </ErrorBoundary>
      <Footer />
    </div>
  );
}

export default App;
